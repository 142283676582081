import {
  BackpackIcon,
  ChevronRightIcon,
  DownloadIcon,
  ExternalLinkIcon,
  FaceIcon,
  FileMinusIcon,
  HomeIcon,
  ReaderIcon,
  SewingPinIcon,
} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {getEventProfile, getRFPSteps} from "@summtech/flok-core/api/event"
import {postRFPStep} from "@summtech/flok-core/api/rfp"
import {getVenue} from "@summtech/flok-core/api/venue"
import LineHeader, {
  LineHeaderSection,
} from "@summtech/flok-core/components/headers/LineHeader"
import ImageGalleryModal from "@summtech/flok-core/components/image-displays/ImageGalleryModal"
import ImagePaneA from "@summtech/flok-core/components/image-displays/ImagePaneA"
import ImageSlideshowModal from "@summtech/flok-core/components/image-displays/ImageSlideshowModal"
import {InfoLabelA, InfoLabelB} from "@summtech/flok-core/components/InfoLabels"
import PageBody from "@summtech/flok-core/components/layout/PageBody"
import {RFPPostStepType, RFPStatus} from "@summtech/flok-core/models/rfp"
import {VenueModel} from "@summtech/flok-core/models/venue"
import {isEventProfileCompleted, splitFileName} from "@summtech/flok-core/utils"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import React, {useState} from "react"
import {useRouteMatch} from "react-router-dom"
import {useEvent} from "../components/EventProvider"
import {LinkOrText} from "../components/LinkOrText"
import {MeetingSpacesTable} from "../components/MeetingSpacesTable"
import {AppRoutes} from "../Stack"

// Change Link or Text import to flok core
let MainBody = styled("div", {
  backgroundColor: "$white",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "30px 20px",
  overflow: "auto",
})

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  padding: "18px 0px",
})
const HeaderTextContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "6px",
  alignItems: "center",
})

const StyledRightIcon = styled(ChevronRightIcon, {
  color: "$gray11",
})

const AboutDiv = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "48px",
  justifyContent: "space-between",
  marginTop: "32px",
})
const AboutDivLeft = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
})

const AboutDivRight = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",

  variants: {
    direction: {
      horizontal: {
        flexDirection: "row",
      },
      vertical: {
        flexDirection: "column",
        width: "400px",
      },
    },
  },
})

const RightContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

const AboutDivLineHeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "24px",
})

const FlexWrap = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "12px",
})

const AirportsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
})

const SectionDivider = styled("div", {
  width: "100%",
  height: "1px",
  backgroundColor: theme.colors.gray6,
  margin: "32px 0px",
})

const SectionIcon = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "28px",
  width: "28px",
  borderRadius: "8px",
  backgroundColor: theme.colors.gray3,
  "& *": {
    color: theme.colors.gray11,
  },
})

const Section = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "32px",
})

const FBSection = styled(Section, {
  maxWidth: "600px",
})

const FoodInner = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

export default function VenuePage() {
  let router = useRouteMatch<{
    venueId: string
  }>()
  let venueId = router.params.venueId
  let venueQuery = useQuery({
    queryFn: () => getVenue(venueId),
    queryKey: ["venue", venueId],
  })
  let venue = venueQuery.data?.venue
  let [event] = useEvent()

  let [photoModalOpen, setPhotoModalOpen] = useState(false)
  let [slideshowImageIdx, setSlideshowImageIdx] = useState<undefined | number>(
    undefined
  )

  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", event.event_profile_ids[0]],
    queryFn: () => getEventProfile(event.event_profile_ids[0]),
  })
  let queryClient = useQueryClient()
  let postRFPStepMutation = useMutation({
    mutationFn: (variables: RFPPostStepType) =>
      postRFPStep({
        event_id: event.id,
        venue_id: venue?.id ?? "",
        ...variables,
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["rfp-steps", event.id])
      queryClient.invalidateQueries([
        "rfp-steps",
        event.id,
        "venue",
        venue?.id ?? "",
      ])
    },
  })
  let rfpStepsQuery = useQuery({
    queryFn: () => getRFPSteps(event.id, venue?.id ?? ""),
    queryKey: ["rfp-steps", event.id, "venue", venue?.id ?? ""],
  })
  let rfpStatus = rfpStepsQuery.isSuccess
    ? new RFPStatus(rfpStepsQuery.data!.rfp_steps)
    : null

  const imageGroups = venue
    ? venue.attr_general_media_groups.map((group) => {
        return {
          tag: group.name,
          images: group.group_membership.map((membership) => {
            return {
              src: membership.venue_media.media.media_url,
              alt:
                membership.venue_media.caption ??
                membership.venue_media.media.alt,
            }
          }),
        }
      })
    : []

  return (
    <PageBody>
      <ImageGalleryModal
        onClickImage={(image, index) => {
          setSlideshowImageIdx(index)
        }}
        open={photoModalOpen}
        hotelName={venue?.name ?? ""}
        onClose={() => {
          setPhotoModalOpen(false)
        }}
        imageGroups={imageGroups}
      />
      <ImageSlideshowModal
        setCurrentImageIndex={setSlideshowImageIdx}
        currentImageIndex={slideshowImageIdx}
        images={imageGroups.flatMap((group) => group.images)}
      />
      <MainBody>
        <HeaderContainer>
          <HeaderTextContainer>
            <LinkOrText
              variant={"heading-lg"}
              text="Explore"
              to={AppRoutes.getPath("ExploreVenues")}
            />
            <StyledRightIcon />
            <Text variant={"heading-lg"}>{venue?.name}</Text>
          </HeaderTextContainer>
          <Button
            variant={"solid"}
            color="brand"
            text={
              rfpStatus?.hasRequestedProposal()
                ? "Proposal Requested"
                : "Request Proposal"
            }
            onClick={() => {
              postRFPStepMutation.mutate({
                type: "PROPOSAL_REQUEST",
                event_profile_id: event.event_profile_ids[0],
              })
            }}
            disabled={
              rfpStatus?.hasRequestedProposal() ||
              !isEventProfileCompleted(eventProfileQuery.data?.event_profile)
            }
          />
        </HeaderContainer>
        {venue && (
          <ImagePaneA
            bottomRightButton={
              <Button
                text="Show All Photos"
                variant={"solid"}
                color="gray"
                onClick={() => {
                  setPhotoModalOpen(true)
                }}
              />
            }
            // @ts-ignore
            images={[
              ...venue.favorited_media.slice(0, 5).map((venueMedia) => {
                return {
                  src: venueMedia.media.media_url,
                  alt: venueMedia.media.alt,
                }
              }),
            ]}
          />
        )}
        {venue && (
          <AboutDiv>
            <AboutHotelSection venue={venue} />
            <LocationHotelSection variant="vertical" venue={venue} />
          </AboutDiv>
        )}
        <SectionDivider />
        <Section>
          <Text variant={"heading-lg"}>Meeting Spaces</Text>
          <MeetingSpacesTable meetingSpaces={venue?.meeting_spaces ?? []} />
          {venue?.attr_meeting_av_menu?.file_url && (
            <a
              style={{textDecoration: "none"}}
              href={venue?.attr_meeting_av_menu?.file_url}>
              <Button
                text="Download AV Menu"
                variant={"outline"}
                color="gray"
                startIcon={<DownloadIcon />}
              />
            </a>
          )}
        </Section>

        <SectionDivider />
        {venue && <HotelFBSection venue={venue} />}
      </MainBody>
    </PageBody>
  )
}

const StyledLink = styled("a", {
  textDecoration: "none",
})

const LinkIcon = styled(SectionIcon, {
  height: "32x",
  width: "32x",
})
export function HotelLinkButton(props: {
  href: string
  target?: string
  text: string
  css?: React.ComponentProps<typeof Button>["css"]
}) {
  return (
    <StyledLink href={props.href} target={props.target}>
      <Button
        css={props.css}
        startIcon={
          <LinkIcon>
            <FileMinusIcon />
          </LinkIcon>
        }
        fullWidth
        text={props.text}
        variant={"outline"}
        color="gray"
        endIcon={
          <div style={{marginLeft: "auto"}}>
            <ExternalLinkIcon />
          </div>
        }
      />
    </StyledLink>
  )
}

export function AboutHotelSection(props: {
  variant?: React.ComponentProps<typeof Text>["variant"]
  venue: VenueModel
}) {
  const aboutLabels = [
    // {
    //   label: {text: "Chain"},
    //   data: {text: "Hyatt Hotels"},
    // },
    // {
    //   label: {text: "Brand"},
    //   data: {text: "Hyatt Regency"},
    // },
    {
      label: {text: "Built"},
      data: {
        text: props.venue.attr_general_built_year
          ? props.venue.attr_general_built_year.toString()
          : "-",
      },
    },
    {
      label: {text: "Renovated"},
      data: {
        text: props.venue.attr_general_renovated_year
          ? props.venue.attr_general_renovated_year.toString()
          : "-",
      },
    },
    {
      label: {text: "Venue Type"},
      data: {
        text: props.venue.attr_general_venue_type
          ? props.venue.attr_general_venue_type[0] +
            props.venue.attr_general_venue_type.substring(1).toLowerCase()
          : "",
      },
    },
    {
      label: {text: "Website"},
      data: {text: "Link", href: props.venue.attr_general_website_url},
    },
  ]

  const RoomLabels = props.venue.venue_rooms.map((space) => {
    return {
      label: {text: space.name},
      data: {
        text: space.num_rooms.toString(),
      },
    }
  })

  return (
    <AboutDivLeft>
      <Text variant={props.variant ?? "heading-lg"}>About</Text>
      <Text variant={"text-sm"}>{props.venue.attr_general_about}</Text>
      <FlexWrap>
        {aboutLabels.map((label) => {
          return (
            <div style={{width: "143px"}}>
              <InfoLabelA {...label}></InfoLabelA>
            </div>
          )
        })}
      </FlexWrap>
      <SectionDivider />
      <AboutDivLineHeaderContainer>
        <LineHeaderSection
          startIcon={
            <SectionIcon>
              <HomeIcon />
            </SectionIcon>
          }
          header={<LineHeader.Text>Guest Rooms</LineHeader.Text>}
          body={
            <FlexWrap>
              {RoomLabels.map((label) => {
                return (
                  <div style={{width: "170px"}}>
                    <InfoLabelA {...label} />
                  </div>
                )
              })}
            </FlexWrap>
          }
        />
        <LineHeaderSection
          startIcon={
            <SectionIcon>
              <BackpackIcon />
            </SectionIcon>
          }
          header={<LineHeader.Text>Meeting Spaces</LineHeader.Text>}
          body={
            <FlexWrap>
              <div style={{width: "130px"}}>
                <InfoLabelA
                  label={{text: "Total Spaces"}}
                  data={{
                    text: Math.max(
                      props.venue.meeting_spaces.length,
                      props.venue.attr_meeting_total_spaces ?? 0
                    ).toString(),
                  }}
                />
              </div>
              <InfoLabelA
                label={{text: "Largest Meeting Space"}}
                data={{
                  text: `${Math.max(
                    ...props.venue.meeting_spaces.map(
                      (space) => space.capacity_max ?? 0
                    ),
                    props.venue.attr_meeting_largest_space_capacity ?? 0
                  )} attendees`,
                }}
              />
            </FlexWrap>
          }
        />
        <LineHeaderSection
          startIcon={
            <SectionIcon>
              <FaceIcon />
            </SectionIcon>
          }
          header={<LineHeader.Text>Amenities</LineHeader.Text>}
          body={
            <FlexWrap>
              {props.venue.venues_to_amenities.slice(0, 7).map((amenity) => {
                return (
                  <div style={{width: "175px"}}>
                    <Text css={{color: "$gray11"}} variant={"text-sm"}>
                      {amenity.name}
                    </Text>
                  </div>
                )
              })}
              {props.venue.venues_to_amenities.length > 7 && (
                <div style={{width: "175px"}}>
                  <Text css={{color: "$gray11"}} variant={"text-sm"}>
                    + {props.venue.venues_to_amenities.length - 7} more
                  </Text>
                </div>
              )}
            </FlexWrap>
          }
        />
      </AboutDivLineHeaderContainer>
    </AboutDivLeft>
  )
}

export function LocationHotelSection(props: {
  variant: "vertical" | "horizontal"
  venue: VenueModel
}) {
  const AirportLabels = props.venue.venues_to_airports.map((venueAirport) => {
    return {
      label: {text: venueAirport.airport.name},
      data: {
        text: `${venueAirport.distance_minutes} min (${venueAirport.distance_miles} miles)`,
      },
    }
  })

  return (
    <AboutDivRight direction={props.variant}>
      <div
        style={{
          height: props.variant === "vertical" ? "275px" : "200px",
          objectFit: "cover",
          borderRadius: "8px",
          overflow: "hidden",
          width: props.variant === "vertical" ? "400px" : "220px",
        }}>
        {/* <AppHotelLocationMap lat={40.73061} long={-73.935242} zoom={9} /> */}
        map!
      </div>
      <RightContainer>
        <LineHeaderSection
          header={<LineHeader.Text>Address</LineHeader.Text>}
          startIcon={<SewingPinIcon />}
          body={
            <Text variant={"text-sm"} css={{color: "$gray11"}}>
              {props.venue.attr_general_address?.formatted_address}
            </Text>
          }
        />
        <LineHeaderSection
          header={<LineHeader.Text>Getting Here</LineHeader.Text>}
          startIcon={<ReaderIcon />}
          body={
            <Text variant={"text-sm"} css={{color: "$gray11"}}>
              {props.venue.attr_general_getting_here}
            </Text>
          }
        />
        <LineHeaderSection
          header={<LineHeader.Text>Airports</LineHeader.Text>}
          startIcon={<ReaderIcon />}
          body={
            <AirportsContainer>
              {AirportLabels.map((label) => {
                return <InfoLabelB {...label} />
              })}
            </AirportsContainer>
          }
        />
      </RightContainer>
    </AboutDivRight>
  )
}

export function HotelFBSection(props: {
  variant?: React.ComponentProps<typeof Text>["variant"]
  venue: VenueModel
}) {
  return (
    <FBSection>
      <Text variant={props.variant ?? "heading-lg"}>Food & Beverages</Text>
      <FoodInner>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          {props.venue.attr_fb_about}
        </Text>
        <LineHeader.Text>Additional F&B Info</LineHeader.Text>
        {props.venue.attr_fb_files_to_venues.map((venueFile) => {
          return (
            <HotelLinkButton
              text={splitFileName(venueFile.file.file_url) as string}
              href={venueFile.file.file_url}
            />
          )
        })}
      </FoodInner>
    </FBSection>
  )
}

import {Button} from "@summtech/flok-base/components/Button"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import FullPage from "@summtech/flok-core/components/layout/FullPage"
import PageBody from "@summtech/flok-core/components/layout/PageBody"
import StepperA from "@summtech/flok-core/components/progress/StepperA"
import {SwitchCases} from "@summtech/flok-core/components/SwitchCases"
import {useRef} from "react"
import {useHistory, useRouteMatch} from "react-router-dom"
import AgendaForm from "../components/event-profile-forms/AgendaForm"
import ContactForm from "../components/event-profile-forms/ContactForm"
import DatesForm from "../components/event-profile-forms/DatesForm"
import GeneralForm from "../components/event-profile-forms/GeneralForm"
import GuestRoomsForm from "../components/event-profile-forms/GuestRoomsForm"
import {AppRoutes} from "../Stack"

let MainBody = styled("div", {
  backgroundColor: "$gray2",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "20px 20px",
  overflow: "auto",
  alignItems: "center",
  width: "100%",
})

let FormContainer = styled("div", {
  backgroundColor: "$white",
  border: "1px solid $gray6",
  borderRadius: theme.shape.borderRadius,
  maxWidth: "560px",
  padding: "20px",
  width: "100%",
})

function getStepperValue(path: string) {
  switch (path) {
    case AppRoutes.getPath("EventProfileGeneralPage"):
      return "general"
    case AppRoutes.getPath("EventProfileDatesPage"):
      return "dates"
    case AppRoutes.getPath("EventProfileGuestRoomsPage"):
      return "guest-rooms"
    case AppRoutes.getPath("EventProfileAgendaPage"):
      return "agenda"
    case AppRoutes.getPath("EventProfileContactPage"):
      return "contact"
  }
  return ""
}

export default function EditEventProfilePage() {
  let submitRef = useRef<HTMLButtonElement>(null)
  let {path} = useRouteMatch()
  let history = useHistory()
  return (
    <PageBody>
      <FullPage>
        <FullPage.Header>
          <FullPage.HeaderLeft>
            <FullPage.HeaderBackButton
              onClick={() => {
                history.push(AppRoutes.getPath("EventProfilePage"))
              }}
            />
          </FullPage.HeaderLeft>
          <FullPage.HeaderCenter>
            <StepperA
              onChange={(newVal) => {
                switch (newVal) {
                  case "general":
                    history.push(AppRoutes.getPath("EventProfileGeneralPage"))
                    break
                  case "dates":
                    history.push(AppRoutes.getPath("EventProfileDatesPage"))
                    break
                  case "guest-rooms":
                    history.push(
                      AppRoutes.getPath("EventProfileGuestRoomsPage")
                    )
                    break
                  case "agenda":
                    history.push(AppRoutes.getPath("EventProfileAgendaPage"))
                    break
                  case "contact":
                    history.push(AppRoutes.getPath("EventProfileContactPage"))
                    break
                }
              }}
              steps={[
                {label: "General", value: "general"},
                {label: "Dates", value: "dates"},
                {label: "Guest Rooms", value: "guest-rooms"},
                {label: "Agenda", value: "agenda"},
                {label: "Contact", value: "contact"},
              ]}
              value={path ? getStepperValue(path) : ""}
            />
          </FullPage.HeaderCenter>
          <FullPage.HeaderRight>
            <Button
              variant={"solid"}
              color="brand"
              text={"Save & Exit"}
              onClick={() => {
                submitRef.current?.click()
              }}
            />
          </FullPage.HeaderRight>
        </FullPage.Header>
        <MainBody>
          <FormContainer>
            <SwitchCases value={path}>
              <SwitchCases.Case
                value={AppRoutes.getPath("EventProfileGeneralPage")}>
                <GeneralForm submitRef={submitRef} />
              </SwitchCases.Case>
              <SwitchCases.Case
                value={AppRoutes.getPath("EventProfileDatesPage")}>
                <DatesForm submitRef={submitRef} />
              </SwitchCases.Case>
              <SwitchCases.Case
                value={AppRoutes.getPath("EventProfileGuestRoomsPage")}>
                <GuestRoomsForm submitRef={submitRef} />
              </SwitchCases.Case>
              <SwitchCases.Case
                value={AppRoutes.getPath("EventProfileAgendaPage")}>
                <AgendaForm submitRef={submitRef} />
              </SwitchCases.Case>
              <SwitchCases.Case
                value={AppRoutes.getPath("EventProfileContactPage")}>
                <ContactForm submitRef={submitRef} />
              </SwitchCases.Case>
            </SwitchCases>
          </FormContainer>
        </MainBody>
      </FullPage>
    </PageBody>
  )
}

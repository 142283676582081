import {Route, Switch} from "react-router-dom"
import EventProvider from "./components/EventProvider"
import EditEventProfilePage from "./pages/EditEventProfilePage"
import EventProfilePage from "./pages/EventProfilePage"
import ExploreHotelsPage from "./pages/ExplorePage"
import HomePage from "./pages/HomePage"
import ProposalsPage from "./pages/ProposalsPage"
import VenuePage from "./pages/VenuePage"

export type PageName = keyof typeof AppRoutes.pages
export class AppRoutes {
  static pages = {
    Home: "/",
    ExploreVenues: "/venues",
    Venue: "/venues/:venueId",
    // Proposals
    ProposalsRequestedPage: "/proposals/requested",
    ProposalsReceivedPage: "/proposals/received",
    ProposalsShortlistPage: "/proposals/shortlist",
    ProposalsContractPage: "/proposals/contract",
    // EventProfile
    EventProfilePage: "/event-profile",
    EventProfileGeneralPage: "/event-profile/general",
    EventProfileDatesPage: "/event-profile/dates",
    EventProfileGuestRoomsPage: "/event-profile/guest-rooms",
    EventProfileAgendaPage: "/event-profile/agenda",
    EventProfileContactPage: "/event-profile/contact",
  }
  static getPath(
    name: PageName,
    pathParams: {[key: string]: string} = {},
    queryParams: {[key: string]: string} = {}
  ): string {
    let path = this.pages[name]
    Object.keys(pathParams).forEach((key) => {
      let value = pathParams[key]
      let toReplace = ":" + key
      path = path.replace(toReplace, value)
    })
    if (Object.keys(queryParams).length > 0) {
      path += "?"
    }
    let queryString = new URLSearchParams(queryParams).toString()
    path += queryString

    return path
  }
}

export default function Stack() {
  return (
    <Switch>
      <EventProvider>
        <Route exact path={AppRoutes.getPath("Home")}>
          <HomePage />
        </Route>
        <Route exact path={AppRoutes.getPath("ExploreVenues")}>
          <ExploreHotelsPage />
        </Route>
        <Route exact path={AppRoutes.getPath("Venue")}>
          <VenuePage />
        </Route>
        <Route
          path={[
            AppRoutes.getPath("ProposalsRequestedPage"),
            AppRoutes.getPath("ProposalsReceivedPage"),
            AppRoutes.getPath("ProposalsShortlistPage"),
            AppRoutes.getPath("ProposalsContractPage"),
          ]}
          exact>
          <ProposalsPage />
        </Route>
        <Route path={[AppRoutes.getPath("EventProfilePage")]} exact>
          <EventProfilePage />
        </Route>
        <Route
          path={[
            AppRoutes.getPath("EventProfileGeneralPage"),
            AppRoutes.getPath("EventProfileDatesPage"),
            AppRoutes.getPath("EventProfileGuestRoomsPage"),
            AppRoutes.getPath("EventProfileAgendaPage"),
            AppRoutes.getPath("EventProfileContactPage"),
          ]}
          exact>
          <EditEventProfilePage />
        </Route>
      </EventProvider>
    </Switch>
  )
}

import {FormField} from "@summtech/flok-base/components/FormField"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {getEventProfile, patchEventProfile} from "@summtech/flok-core/api/event"
import RadioButton from "@summtech/flok-core/components/buttons/RadioButton"
import {EventProfileModel} from "@summtech/flok-core/models/event"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useFormik} from "formik"
import _ from "lodash"
import {useHistory} from "react-router"
import * as yup from "yup"
import {AppRoutes} from "../../Stack"
// Change import to flok-core
import BeforeUnload from "../BeforeUnload"
import {useEvent} from "../EventProvider"
import BackAndForthButtons from "./BackAndForthButtons"

const FormContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
})
const StyledContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
})

const TypeOptions = [
  {label: "Company Retreat/Offsite", value: "retreat"},
  {label: "President's Club/Incentive Trip", value: "incentive_trip"},
  {label: "Sales Kickoff (SKO)", value: "sales_kickoff"},
  {label: "Conference", value: "conference"},
  {label: "Other", value: "other"},
]

const FlexWrap = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  gap: "8px",
})

export default function GeneralForm(props: {
  submitRef: React.RefObject<HTMLButtonElement>
}) {
  let [event] = useEvent()
  let history = useHistory()
  let eventProfileId = event.event_profile_ids[0]
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  let queryClient = useQueryClient()
  let eventProfile = eventProfileQuery.data?.event_profile
  let patchEventProfileMutation = useMutation({
    mutationFn: (variables: {eventProfile: Partial<EventProfileModel>}) =>
      patchEventProfile(eventProfileId, variables.eventProfile),
    onMutate: (variables) => {
      queryClient.setQueryData(["eventProfile", eventProfileId], {
        event_profile: {
          ...eventProfileQuery.data?.event_profile,
          ...variables.eventProfile,
        },
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
  })
  async function handleSubmit(
    values: typeof formik.values,
    completed: boolean
  ) {
    let response = await formik.validateForm()
    return patchEventProfileMutation.mutateAsync({
      eventProfile: {
        ...values,
        general_completed: completed || Object.values(response).length === 0,
      },
    })
  }

  let formik = useFormik({
    initialValues: {
      event_name: eventProfile?.event_name ?? "",
      event_type: eventProfile?.event_type ?? "",
      event_num_guests: eventProfile?.event_num_guests ?? 0,
    },
    onSubmit: async (values) => {
      await handleSubmit(values, true)
      history.push(AppRoutes.getPath("EventProfileDatesPage"))
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      event_name: yup.string().required("Event name is required"),
    }),
  })

  return (
    <FormContainer>
      <StyledContainer>
        <Text variant={"heading-lg"}>Tell us more about your event!</Text>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          This questionnaire will help us to request receive fast and accurate
          quotes for your event. It should not take more than 10 minutes.
        </Text>
      </StyledContainer>
      <FormField
        type="textfield"
        label="Event Name"
        fullWidth
        value={formik.values.event_name}
        onChange={formik.handleChange}
        id="event_name"
        errorMessage={formik.errors.event_name}
      />
      <StyledContainer>
        <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
          Event Type
        </Text>
        <FlexWrap>
          {TypeOptions.map((option) => (
            <RadioButton
              fullWidth
              css={{
                width: "50%",
                minWidth: "252px",
                flex: 1,
                maxWidth: "252px",
              }}
              onClick={(newValue) => {
                formik.setFieldValue("event_type", newValue)
              }}
              selected={formik.values.event_type === option.value}
              text={option.label}
              value={option.value}
            />
          ))}
        </FlexWrap>
      </StyledContainer>
      {formik.values.event_type === "other" && (
        <FormField
          type="select"
          label="Other Event Types"
          fullWidth
          onChange={() => {}}
        />
      )}
      <BeforeUnload
        when={!_.isEqual(formik.values, formik.initialValues)}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <FormField
        value={formik.values.event_num_guests}
        onChange={formik.handleChange}
        id="event_num_guests"
        type="number"
        label="How many guests do you expect at your event?"
        fullWidth
      />
      <BackAndForthButtons
        first
        onNext={() => {
          formik.handleSubmit()
        }}
      />
      <button
        style={{display: "none"}}
        ref={props.submitRef}
        onClick={async () => {
          await handleSubmit(formik.values, false)
          history.push(AppRoutes.getPath("EventProfilePage"))
        }}
      />
    </FormContainer>
  )
}

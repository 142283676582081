import {FormField} from "@summtech/flok-base/components/FormField"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {getEventProfile, patchEventProfile} from "@summtech/flok-core/api/event"
import {EventProfileModel} from "@summtech/flok-core/models/event"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useFormik} from "formik"
import _ from "lodash"
import {useHistory} from "react-router"
import * as yup from "yup"
import {AppRoutes} from "../../Stack"
import BeforeUnload from "../BeforeUnload"
import {useEvent} from "../EventProvider"
import BackAndForthButtons from "./BackAndForthButtons"

const FormContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
})

const StyledContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
})

const SideBySideContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "16px",
  width: "100%",
})
const SideContainer = styled("div", {
  width: "50%",
  flex: 1,
})
export default function ContactForm(props: {
  submitRef: React.RefObject<HTMLButtonElement>
}) {
  let [event] = useEvent()
  let eventProfileId = event.event_profile_ids[0]
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  let queryClient = useQueryClient()
  let history = useHistory()
  let eventProfile = eventProfileQuery.data?.event_profile
  let patchEventProfileMutation = useMutation({
    mutationFn: (variables: {eventProfile: Partial<EventProfileModel>}) =>
      patchEventProfile(eventProfileId, variables.eventProfile),
    onMutate: (variables) => {
      queryClient.setQueryData(["eventProfile", eventProfileId], {
        event_profile: {
          ...(eventProfileQuery.data?.event_profile ?? {}),
          ...variables.eventProfile,
        },
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
  })
  async function handleSubmit(
    values: typeof formik.values,
    completed: boolean
  ) {
    let response = await formik.validateForm()
    return patchEventProfileMutation.mutateAsync({
      eventProfile: {
        ...values,
        contact_completed: completed || Object.values(response).length === 0,
      } as Partial<EventProfileModel>,
    })
  }
  let formik = useFormik({
    initialValues: {
      contact_company_name: eventProfile?.contact_company_name ?? "",
      contact_addr_street: eventProfile?.contact_addr_street ?? "",
      contact_addr_zip: eventProfile?.contact_addr_zip ?? "",
      contact_addr_city: eventProfile?.contact_addr_city ?? "",
      contact_addr_country: eventProfile?.contact_addr_country ?? "",
      contact_email: eventProfile?.contact_email ?? "",
      contact_phone: eventProfile?.contact_phone ?? "",
      contact_method: eventProfile?.contact_method ?? "",
    },
    onSubmit: async (values) => {
      await handleSubmit(values, true)
      history.push(AppRoutes.getPath("EventProfileAgendaPage"))
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      contact_company_name: yup.string().required("Event name is required"),
      contact_phone: yup.string().required("Phone number is required"),
      contact_method: yup.string().required("Contact method is required"),
      contact_email: yup.string().required("Email is required"),
    }),
  })

  return (
    <FormContainer>
      <BeforeUnload
        when={!_.isEqual(formik.values, formik.initialValues)}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <StyledContainer>
        <Text variant={"heading-lg"}>Let us know your contact information</Text>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Text>
      </StyledContainer>
      <FormField
        type="textfield"
        label="Company Name"
        fullWidth
        placeholder="Company Name"
        value={formik.values.contact_company_name}
        onChange={formik.handleChange}
        id="contact_company_name"
        errorMessage={formik.errors.contact_company_name}
      />
      <SideBySideContainer>
        <SideContainer>
          <FormField
            type="textfield"
            label="Street"
            fullWidth
            placeholder="Street"
            value={formik.values.contact_addr_street}
            onChange={formik.handleChange}
            id="contact_addr_street"
            errorMessage={formik.errors.contact_addr_street}
          />
        </SideContainer>
        <SideContainer>
          <FormField
            type="textfield"
            label="Zip Code"
            fullWidth
            placeholder="12345"
            value={formik.values.contact_addr_zip}
            onChange={formik.handleChange}
            id="contact_addr_zip"
            errorMessage={formik.errors.contact_addr_zip}
          />
        </SideContainer>
      </SideBySideContainer>
      <SideBySideContainer>
        <SideContainer>
          <FormField
            type="textfield"
            label="City"
            fullWidth
            placeholder="City"
            value={formik.values.contact_addr_city}
            onChange={formik.handleChange}
            id="contact_addr_city"
            errorMessage={formik.errors.contact_addr_city}
          />
        </SideContainer>
        <SideContainer>
          <FormField
            type="textfield"
            label="Country"
            fullWidth
            placeholder="Select Country"
            value={formik.values.contact_addr_country}
            onChange={formik.handleChange}
            id="contact_addr_country"
            errorMessage={formik.errors.contact_addr_country}
          />
        </SideContainer>
      </SideBySideContainer>
      <FormField
        type="textfield"
        label="Email"
        fullWidth
        placeholder="Email"
        value={formik.values.contact_email}
        onChange={formik.handleChange}
        id="contact_email"
        errorMessage={formik.errors.contact_email}
      />
      <FormField
        type="textfield"
        label="Phone"
        fullWidth
        placeholder="Phone"
        value={formik.values.contact_phone}
        onChange={formik.handleChange}
        id="contact_phone"
        errorMessage={formik.errors.contact_phone}
      />
      <FormField
        type="select"
        label="How do you prefer to be contacted?"
        value={formik.values.contact_method}
        onChange={(newVal) => {
          formik.setFieldValue("contact_method", newVal)
        }}
        fullWidth>
        <SelectItem value="EMAIL" label="Email" />
        <SelectItem value="PHONE" label="Phone" />
      </FormField>
      <BackAndForthButtons
        onBack={() => {
          history.push(AppRoutes.getPath("EventProfileAgendaPage"))
        }}
        onNext={() => {
          formik.handleSubmit()
        }}
        final
      />
      <button
        style={{display: "none"}}
        ref={props.submitRef}
        onClick={async () => {
          await handleSubmit(formik.values, false)
          history.push(AppRoutes.getPath("EventProfilePage"))
        }}
      />
    </FormContainer>
  )
}

import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import AppModal, {AppModalProps} from "@summtech/flok-core/components/AppModal"
import {useState} from "react"
import StepperC from "@summtech/flok-core/components/progress/StepperC"

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  marginBottom: "20px",
})
export default function HowItWorksModal(props: AppModalProps) {
  return (
    <AppModal {...props}>
      <AppModal.Body css={{padding: "20px", width: "460px"}}>
        <HeaderContainer>
          <Text variant={"heading-base"} css={{color: "$gray12"}}>
            How It Works
          </Text>
          <Text variant={"text-sm"} css={{color: "$gray11"}}>
            Lorem Ipsum
          </Text>
        </HeaderContainer>
        <StepperC
          numbered
          steps={[
            {
              label: "Request Proposals",
              completed: false,
              subtitle: "Request pricing & availability from hotels",
              rejected: false,
            },
            {
              label: "Receive proposals",
              completed: false,
              subtitle: "Request pricing & availability from hotels",
              rejected: false,
            },
            {
              label: "Add to shortlist",
              completed: false,
              subtitle: "Request pricing & availability from hotels",
              rejected: false,
            },
            {
              label: "Request contract",
              completed: false,
              subtitle: "Request pricing & availability from hotels",
              rejected: false,
            },
          ]}
        />
      </AppModal.Body>
      <AppModal.Footer>
        <Button
          text="Got It!"
          onClick={props.onClose}
          color="brand"
          variant="solid"
        />
      </AppModal.Footer>
    </AppModal>
  )
}

export function HowItWorksModalButton(props: {text?: string}) {
  let [howItWorksModalOpen, setHowItWorksModalOpen] = useState(false)
  return (
    <>
      <HowItWorksModal
        open={howItWorksModalOpen}
        onClose={() => {
          setHowItWorksModalOpen(false)
        }}
      />
      <Button
        text={props.text ?? "How It Works"}
        color="gray"
        variant="outline"
        onClick={() => {
          setHowItWorksModalOpen(true)
        }}
      />
    </>
  )
}

import {PlusIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Checkbox} from "@summtech/flok-base/components/Checkbox"
import {FormField} from "@summtech/flok-base/components/FormField"
import {FormLabel} from "@summtech/flok-base/components/FormLabel"
import {Switch} from "@summtech/flok-base/components/Switch"
import {Text} from "@summtech/flok-base/components/Text"
import {css, styled, theme} from "@summtech/flok-base/stitches.config"
import {
  getEventProfile,
  patchEventProfile,
  patchEventProfileOccupancyDay,
  postEventProfileOccupancyDay,
} from "@summtech/flok-core/api/event"
import AppModal, {AppModalProps} from "@summtech/flok-core/components/AppModal"
import {Divider} from "@summtech/flok-core/components/common"
import {AccordionItem} from "@summtech/flok-core/components/layout/AccordionItem"
import {
  EventProfileModel,
  EventProfileOccupancyDayModel,
} from "@summtech/flok-core/models/event"
import {
  dateFormat,
  differenceInDays,
  getIsoDate,
} from "@summtech/flok-core/utils"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useFormik} from "formik"
import _ from "lodash"
import {useState} from "react"
import {useHistory} from "react-router"
import {AppRoutes} from "../../Stack"
// Change import to flok-core
import BeforeUnload from "../BeforeUnload"
import {useEvent} from "../EventProvider"
import BackAndForthButtons from "./BackAndForthButtons"

const FormContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
})

const StyledContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
})

const EndIconContainer = styled("div", {
  marginLeft: "auto",
})

export default function GuestRoomsForm(props: {
  submitRef: React.RefObject<HTMLButtonElement>
}) {
  let [event] = useEvent()
  let eventProfileId = event.event_profile_ids[0]
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  let queryClient = useQueryClient()
  let eventProfile = eventProfileQuery.data?.event_profile
  let days =
    eventProfile?.dates_arrival && eventProfile?.dates_departure
      ? differenceInDays(
          eventProfile.dates_departure,
          eventProfile.dates_arrival
        )
      : 0

  let startDate = eventProfile?.dates_arrival
  let occupancyDays = eventProfile?.occupancy_days ?? []

  let patchEventProfileMutation = useMutation({
    mutationFn: (variables: {eventProfile: Partial<EventProfileModel>}) =>
      patchEventProfile(eventProfileId, variables.eventProfile),
    onMutate: (variables) => {
      queryClient.setQueryData(["eventProfile", eventProfileId], {
        event_profile: {
          ...eventProfileQuery.data?.event_profile,
          ...variables.eventProfile,
        },
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
  })

  let patchEventProfileOccupancyDayMutation = useMutation({
    mutationFn: (variables: {
      occupancyDay: Partial<EventProfileOccupancyDayModel>
      occupancyDayId: number
    }) =>
      patchEventProfileOccupancyDay(variables.occupancyDayId, {
        occupancy_day: variables.occupancyDay,
      }),
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
    onMutate: (variables) => {
      let idx = occupancyDays.findIndex(
        (day) => day.id === variables.occupancyDayId
      )
      let replacementDays = [
        ...occupancyDays,
      ] as Partial<EventProfileOccupancyDayModel>[]
      replacementDays[idx] = variables.occupancyDay
      queryClient.setQueriesData(["eventProfile", eventProfileId], {
        event_profile: {
          ...eventProfileQuery.data?.event_profile,
          occupancy_days: replacementDays,
        },
      })
    },
  })
  let postEventProfileOccupancyDayMutation = useMutation({
    mutationFn: (variables: {
      occupancyDay: Partial<EventProfileOccupancyDayModel>
    }) => postEventProfileOccupancyDay(variables.occupancyDay),
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
    onMutate: (variables) => {
      queryClient.setQueriesData(["eventProfile", eventProfileId], {
        event_profile: {
          ...eventProfileQuery.data?.event_profile,
          occupancy_days: [
            ...(eventProfileQuery.data?.event_profile.occupancy_days ?? []),
            variables.occupancyDay,
          ],
        },
      })
    },
  })
  let history = useHistory()

  async function handleSubmit(
    values: typeof formik.values,
    completed: boolean
  ) {
    let response = await formik.validateForm()
    await patchEventProfileMutation.mutate({
      eventProfile: {
        dates_shoulder_pre: values.dates_shoulder_pre,
        dates_shoulder_post: values.dates_shoulder_post,
        rooms_communal_options: values.rooms_communal_options === "true",
        rooms_extend_group_rate: values.rooms_extend_group_rate === "true",
        rooms_same_occupancy: values.rooms_same_occupancy,
        rooms_same_occupancy_single: values.rooms_same_occupancy_single,
        rooms_same_occupancy_double: values.rooms_same_occupancy_double,
        rooms_completed: completed || Object.values(response).length === 0,
      },
    })

    await Promise.all(
      Object.entries(values.rooms_required_main).map(([key, value]) => {
        let existingDay = occupancyDays.find((day) => {
          return day.day === parseInt(key) && day.day_type === "MAIN"
        })
        if (existingDay) {
          return patchEventProfileOccupancyDayMutation.mutateAsync({
            occupancyDay: {
              rooms_single: value.single ?? 0,
              rooms_double: value.double ?? 0,
              day: parseInt(key),
              day_type: "MAIN",
            },
            occupancyDayId: existingDay.id,
          })
        } else {
          return postEventProfileOccupancyDayMutation.mutateAsync({
            occupancyDay: {
              rooms_single: value.single ?? 0,
              rooms_double: value.double ?? 0,
              day: parseInt(key),
              day_type: "MAIN",
              event_profile_id: eventProfileId,
            },
          })
        }
      })
    )
    await Promise.all(
      Object.entries(values.rooms_required_pre).map(([key, value]) => {
        let existingDay = occupancyDays.find((day) => {
          return day.day === parseInt(key) && day.day_type === "PRE"
        })
        if (existingDay) {
          return patchEventProfileOccupancyDayMutation.mutateAsync({
            occupancyDay: {
              rooms_single: value.single ?? 0,
              rooms_double: value.double ?? 0,
              day: parseInt(key),
              day_type: "PRE",
            },
            occupancyDayId: existingDay.id,
          })
        } else {
          return postEventProfileOccupancyDayMutation.mutateAsync({
            occupancyDay: {
              rooms_single: value.single ?? 0,
              rooms_double: value.double ?? 0,
              day: parseInt(key),
              day_type: "PRE",
              event_profile_id: eventProfileId,
            },
          })
        }
      })
    )

    return Promise.all(
      Object.entries(values.rooms_required_post).map(([key, value]) => {
        let existingDay = occupancyDays.find((day) => {
          return day.day === parseInt(key) && day.day_type === "POST"
        })
        if (existingDay) {
          return patchEventProfileOccupancyDayMutation.mutateAsync({
            occupancyDay: {
              rooms_single: value.single ?? 0,
              rooms_double: value.double ?? 0,
              day: parseInt(key),
              day_type: "POST",
            },
            occupancyDayId: existingDay.id,
          })
        } else {
          return postEventProfileOccupancyDayMutation.mutateAsync({
            occupancyDay: {
              rooms_single: value.single ?? 0,
              rooms_double: value.double ?? 0,
              day: parseInt(key),
              day_type: "POST",
              event_profile_id: eventProfileId,
            },
          })
        }
      })
    )
  }
  let formik = useFormik({
    initialValues: {
      dates_shoulder_pre: eventProfile?.dates_shoulder_pre ?? 0,
      dates_shoulder_post: eventProfile?.dates_shoulder_post ?? 0,
      rooms_communal_options: eventProfile?.rooms_communal_options
        ? eventProfile.rooms_communal_options.toString()
        : "",
      rooms_extend_group_rate: eventProfile?.rooms_extend_group_rate
        ? eventProfile.rooms_extend_group_rate.toString()
        : "",
      rooms_same_occupancy: eventProfile?.rooms_same_occupancy ?? true,
      rooms_same_occupancy_single:
        eventProfile?.rooms_same_occupancy_single ?? 0,
      rooms_same_occupancy_double:
        eventProfile?.rooms_same_occupancy_double ?? 0,
      rooms_required_main: occupancyDays
        .filter((day) => day.day_type === "MAIN")
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.day]: {
              single: curr.rooms_single,
              double: curr.rooms_double,
            },
          }
        }, {}) as {
        [key: number]: {single: number; double: number}
      },
      rooms_required_pre: occupancyDays
        .filter((day) => day.day_type === "PRE")
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.day]: {
              single: curr.rooms_single,
              double: curr.rooms_double,
            },
          }
        }, {}) as {
        [key: number]: {single: number; double: number}
      },
      rooms_required_post: occupancyDays
        .filter((day) => day.day_type === "POST")
        .reduce((prev, curr) => {
          return {
            ...prev,
            [curr.day]: {
              single: curr.rooms_single,
              double: curr.rooms_double,
            },
          }
        }, {}) as {
        [key: number]: {single: number; double: number}
      },
    },
    onSubmit: async (values) => {
      await handleSubmit(values, true)
      history.push(AppRoutes.getPath("EventProfileAgendaPage"))
    },
    enableReinitialize: true,
  })

  let [shoulderModalOpen, setShoulderModalOpen] = useState(false)

  return (
    <FormContainer>
      <BeforeUnload
        when={!_.isEqual(formik.values, formik.initialValues)}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <ShoulderDaysModal
        days={days}
        startDate={startDate ?? ""}
        mainEventDate={
          startDate
            ? `${dateFormat(getIsoDate(startDate))} - ${dateFormat(
                getIsoDate(startDate, days)
              )}`
            : "Unknown"
        }
        open={shoulderModalOpen}
        precedingShoulderDays={formik.values.dates_shoulder_pre}
        setPrecedingShoulderDays={(days) => {
          formik.setFieldValue("dates_shoulder_pre", days)
        }}
        followingShoulderDays={formik.values.dates_shoulder_post}
        setFollowingShoulderDays={(days) => {
          formik.setFieldValue("dates_shoulder_post", days)
        }}
        onClose={() => {
          setShoulderModalOpen(false)
        }}
      />
      <StyledContainer>
        <Text variant={"heading-lg"}>How many guest rooms do you need?</Text>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          Reserve guest rooms for the event duration. You can change this at any
          time and will only be charged for the rooms you ultimately need.
        </Text>
      </StyledContainer>
      {new Array(formik.values.dates_shoulder_pre).fill(0).map((_, i) => {
        const dayBefore = formik.values.dates_shoulder_pre - i - 1
        return (
          <GuestRoomsDropdown
            key={`${dayBefore}-preceding`}
            singleRooms={
              formik.values.rooms_required_pre[dayBefore]?.single ?? 0
            }
            doubleRooms={
              formik.values.rooms_required_pre[dayBefore]?.double ?? 0
            }
            onChangeSingleRooms={(newVal) => {
              formik.setFieldValue(
                `rooms_required_pre.${dayBefore.toString()}.single`,
                newVal
              )
            }}
            onChangeDoubleRooms={(newVal) => {
              formik.setFieldValue(
                `rooms_required_pre.${dayBefore.toString()}.double`,
                newVal
              )
            }}
            header={
              startDate
                ? `${new Intl.DateTimeFormat(undefined, {
                    day: "numeric",
                    month: "long",
                    timeZone: "UTC",
                  }).format(
                    getIsoDate(startDate, -dayBefore - 1)
                  )} (Shoulder Day)`
                : "Shoulder Day"
            }
          />
        )
      })}
      {formik.values.rooms_same_occupancy ? (
        <GuestRoomsDropdown
          defaultExpanded
          singleRooms={formik.values.rooms_same_occupancy_single}
          doubleRooms={formik.values.rooms_same_occupancy_double}
          onChangeSingleRooms={(newVal) => {
            formik.setFieldValue("rooms_same_occupancy_single", newVal)
          }}
          onChangeDoubleRooms={(newVal) => {
            formik.setFieldValue("rooms_same_occupancy_double", newVal)
          }}
          header={
            startDate
              ? `${new Intl.DateTimeFormat(undefined, {
                  day: "numeric",
                  month: "long",
                  timeZone: "UTC",
                }).format(new Date(startDate))} - ${new Intl.DateTimeFormat(
                  undefined,
                  {
                    day: "numeric",
                    month: "long",
                    timeZone: "UTC",
                  }
                ).format(getIsoDate(startDate, days - 1))}`
              : "Main Event"
          }
          sameOccupancy={formik.values.rooms_same_occupancy}
          onChangeSameOccupancy={(newVal) => {
            formik.setFieldValue("rooms_same_occupancy", newVal)
            new Array(days).fill(0).forEach((_, i) => {
              if (!formik.values.rooms_required_main[i]) {
                formik.setFieldValue(
                  `rooms_required_main.${i}.single`,
                  formik.values.rooms_same_occupancy_single
                )
                formik.setFieldValue(
                  `rooms_required_main.${i}.double`,
                  formik.values.rooms_same_occupancy_double
                )
              }
            })
          }}
        />
      ) : (
        <>
          {new Array(days).fill(0).map((_, i) => {
            return (
              <GuestRoomsDropdown
                key={i}
                singleRooms={formik.values.rooms_required_main[i]?.single ?? 0}
                doubleRooms={formik.values.rooms_required_main[i]?.double ?? 0}
                defaultExpanded={i === 0}
                onChangeSingleRooms={(newVal) => {
                  formik.setFieldValue(
                    `rooms_required_main.${i.toString()}.single`,
                    newVal
                  )
                }}
                onChangeDoubleRooms={(newVal) => {
                  formik.setFieldValue(
                    `rooms_required_main.${i.toString()}.double`,
                    newVal
                  )
                }}
                header={
                  startDate
                    ? new Intl.DateTimeFormat(undefined, {
                        day: "numeric",
                        month: "long",
                        timeZone: "UTC",
                      }).format(getIsoDate(startDate, i))
                    : "Main Event"
                }
                sameOccupancy={formik.values.rooms_same_occupancy}
                onChangeSameOccupancy={
                  i === 0
                    ? () => {
                        formik.setFieldValue("rooms_same_occupancy", true)
                        formik.setFieldValue(
                          `rooms_same_occupancy_single`,
                          formik.values.rooms_required_main[i]?.single ?? 0
                        )
                        formik.setFieldValue(
                          `rooms_same_occupancy_double`,
                          formik.values.rooms_required_main[i]?.double ?? 0
                        )
                      }
                    : undefined
                }
              />
            )
          })}
        </>
      )}
      {new Array(formik.values.dates_shoulder_post).fill(0).map((_, i) => {
        return (
          <GuestRoomsDropdown
            key={`${i}-following`}
            singleRooms={formik.values.rooms_required_post[i]?.single ?? 0}
            doubleRooms={formik.values.rooms_required_post[i]?.double ?? 0}
            onChangeSingleRooms={(newVal) => {
              formik.setFieldValue(`rooms_required_post.${i}.single`, newVal)
            }}
            onChangeDoubleRooms={(newVal) => {
              formik.setFieldValue(`rooms_required_post.${i}.double`, newVal)
            }}
            header={
              startDate
                ? `${new Intl.DateTimeFormat(undefined, {
                    day: "numeric",
                    month: "long",
                    timeZone: "UTC",
                  }).format(getIsoDate(startDate, i + days))} (Shoulder Day)`
                : "Shoulder Day"
            }
          />
        )
      })}
      <Button
        variant={"outline"}
        color="gray"
        text="Add Shoulder Day"
        startIcon={<PlusIcon />}
        fullWidth
        css={{height: "44px"}}
        endIcon={
          <EndIconContainer>
            {/* <AppMoreInfoIcon tooltipText="Shoulder days can be added to accomodate travellers who need rooms outside of the main event" /> */}
            more info icon*
          </EndIconContainer>
        }
        onClick={() => {
          setShoulderModalOpen(true)
        }}
      />
      <FormField
        type="radio"
        value={formik.values.rooms_communal_options}
        onValueChange={(value) => {
          formik.setFieldValue("rooms_communal_options", value)
        }}
        label="Do you want to see communal living options if available (i.e. houses, villas, suites, etc.)?"
        options={[
          {label: "Yes", value: "true"},
          {label: "No", value: "false"},
        ]}
      />
      <FormField
        type="radio"
        label="Do you want individuals to be able come early or leave later at their own expense?"
        value={formik.values.rooms_extend_group_rate}
        onValueChange={(value) => {
          formik.setFieldValue("rooms_extend_group_rate", value)
        }}
        options={[
          {label: "Yes", value: "true"},
          {label: "No", value: "false"},
        ]}
      />
      <BackAndForthButtons
        onBack={() => {
          history.push(AppRoutes.getPath("EventProfileDatesPage"))
        }}
        onNext={() => {
          formik.handleSubmit()
        }}
      />
      <button
        style={{display: "none"}}
        ref={props.submitRef}
        onClick={async () => {
          await handleSubmit(formik.values, false)
          history.push(AppRoutes.getPath("EventProfilePage"))
        }}
      />
    </FormContainer>
  )
}

const GuestRoomsBody = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  paddingLeft: "26px",
})

const FieldContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  width: "100%",
  flexWrap: "wrap",
  alignItems: "center",
})

const SwitchContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  width: "100%",
  flexWrap: "nowrap",
  alignItems: "flex-start",
})

const LabelContainer = styled("div", {
  minWidth: "140px",
})

const AccordionHeader = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
})
function GuestRoomsDropdown(props: {
  header: string
  sameOccupancy?: boolean
  onChangeSameOccupancy?: (sameOccupancy: boolean) => void
  singleRooms?: number
  doubleRooms?: number
  onChangeSingleRooms: (singleRooms: number) => void
  onChangeDoubleRooms: (doubleRooms: number) => void
  defaultExpanded?: boolean
}) {
  return (
    <AccordionItem
      defaultExpanded={props.defaultExpanded}
      header={
        <AccordionHeader>
          <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
            {props.header}
          </Text>
          <Text variant={"text-sm"} css={{color: "$gray11"}}>
            {(isNaN(props.doubleRooms ?? 0) ? 0 : props.doubleRooms ?? 0) +
              (isNaN(props.singleRooms ?? 0) ? 0 : props.singleRooms ?? 0)}{" "}
            guest rooms
          </Text>
        </AccordionHeader>
      }
      details={
        <GuestRoomsBody>
          <FieldContainer>
            <LabelContainer>
              <FormLabel text={"Single"} />
            </LabelContainer>
            <RoomsInput
              defaultValue={0}
              value={props.singleRooms}
              onChange={(e) => {
                props.onChangeSingleRooms(parseInt(e.target.value))
              }}
            />
          </FieldContainer>
          <FieldContainer>
            <LabelContainer>
              <FormLabel text={"Double"} />
            </LabelContainer>
            <RoomsInput
              defaultValue={0}
              value={props.doubleRooms}
              onChange={(e) => {
                props.onChangeDoubleRooms(parseInt(e.target.value))
              }}
            />
          </FieldContainer>
          {props.onChangeSameOccupancy && (
            <>
              <Divider />
              <SwitchContainer>
                <Switch
                  checked={props.sameOccupancy}
                  onChange={(checked) => {
                    props.onChangeSameOccupancy!(checked)
                  }}
                />
                <FormLabel text={"Same occupancy for all days"} />
              </SwitchContainer>
            </>
          )}
        </GuestRoomsBody>
      }
    />
  )
}

const StyledTexfieldInputCss = css({
  outline: "none",
  width: "360px",
  background: "$white",
  color: "$gray12",
  padding: "8px 12px",
  "&::placeholder": {
    color: "$gray11",
    opacity: 1,
  },
  border: "none",
  fontWeight: 400,
  fontSize: 14,
  lineHeight: "20px",
  fontFamily: "Inter",
  "&:disabled": {
    opacity: 0.5,
    cursor: "not-allowed",
  },
  variants: {
    fullWidth: {
      true: {
        width: "100%",
        flex: 1,
      },
    },
  },
})

const StyledInputContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows.shadowInner,
  borderStyle: "solid",
  overflow: "hidden",
  borderWidth: "1px",
  borderColor: "$gray7",
  width: "100%",
  maxWidth: "298px",
  "&:hover": {
    borderColor: "$gray8",
  },
  "&:focus": {
    borderColor: "$blue9",
    boxShadowColor: "$focus",
  },
  variants: {
    error: {
      true: {
        borderColor: "$red7",
        "&:hover": {
          borderColor: "$red8",
        },
        "&:focus": {
          borderColor: "$red9",
          boxShadowColor: "$error",
        },
      },
      false: {},
    },
  },
})

const StyledInput = styled("input", StyledTexfieldInputCss)

const RoomsDiv = styled("div", {
  padding: "8px 12px",
  background: "$gray2",
  borderLeft: "1px solid $gray7",
  height: "100%",
})
function RoomsInput(
  props: React.ComponentProps<typeof StyledInput> & {error?: boolean}
) {
  let {error, ...rest} = props
  return (
    <StyledInputContainer error={error}>
      <StyledInput {...rest} type="number" fullWidth />
      <RoomsDiv>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          Rooms
        </Text>
      </RoomsDiv>
    </StyledInputContainer>
  )
}

const MainEventTextContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
})

const BodyContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
})

const InstructionsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "6px",
})

function ShoulderDaysModal(
  props: AppModalProps & {
    precedingShoulderDays: number
    setPrecedingShoulderDays: (days: number) => void
    mainEventDate: string
    followingShoulderDays: number
    setFollowingShoulderDays: (days: number) => void
    startDate: string
    days: number
  }
) {
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>Add Shoulder Day</AppModal.Header>
      <AppModal.Body>
        <BodyContainer>
          <InstructionsContainer>
            <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
              Select shoulder days
            </Text>
            <Text variant={"text-sm"} css={{color: "$gray11"}}>
              Shoulder days are additional days before or after the main event
            </Text>
          </InstructionsContainer>
          {new Array(props.precedingShoulderDays + 1).fill(0).map((_, i) => {
            let selected = i !== 0
            return (
              <Button
                text={getIsoDate(
                  props.startDate,
                  -Math.abs(props.precedingShoulderDays - i)
                ).toDateString()}
                startIcon={<Checkbox checked={selected} />}
                variant={"outline"}
                color="gray"
                fullWidth
                onClick={() => {
                  if (!selected) {
                    props.setPrecedingShoulderDays(
                      props.precedingShoulderDays + 1
                    )
                  } else {
                    props.setPrecedingShoulderDays(
                      props.precedingShoulderDays - i
                    )
                  }
                }}
              />
            )
          })}
          <MainEventTextContainer>
            <Divider css={{width: "24px"}} />
            <Text variant={"text-sm"} css={{color: "$gray11"}}>
              {`Main Event (${props.mainEventDate})`}
            </Text>
            <Divider css={{flex: 1}} />
          </MainEventTextContainer>
          {new Array(props.followingShoulderDays + 1).fill(0).map((_, i) => {
            let selected = i !== props.followingShoulderDays
            return (
              <Button
                text={getIsoDate(
                  props.startDate,
                  props.days + i + 1
                ).toDateString()}
                startIcon={<Checkbox checked={selected} />}
                variant={"outline"}
                color="gray"
                fullWidth
                onClick={() => {
                  if (!selected) {
                    props.setFollowingShoulderDays(
                      props.followingShoulderDays + 1
                    )
                  } else {
                    props.setFollowingShoulderDays(i)
                  }
                }}
              />
            )
          })}
        </BodyContainer>
      </AppModal.Body>
    </AppModal>
  )
}

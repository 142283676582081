import {CheckIcon} from "@radix-ui/react-icons"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Button} from "@summtech/flok-base/components/Button"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {getRFPSteps} from "@summtech/flok-core/api/event"
import {getVenue, getVenues} from "@summtech/flok-core/api/venue"
import {HotelCardC} from "@summtech/flok-core/components/cards/HotelCardC"
import {InfoLabelA} from "@summtech/flok-core/components/InfoLabels"
import PageBody from "@summtech/flok-core/components/layout/PageBody"
import {LinkOrText} from "@summtech/flok-core/components/LinkOrText"
import {RFPStatus} from "@summtech/flok-core/models/rfp"
import {useQuery} from "@tanstack/react-query"
import {Link} from "react-router-dom"
import {useEvent} from "../components/EventProvider"
import {AppRoutes} from "../Stack"

const StyledLink = styled(Link, {
  textDecoration: "none",
})

const BlueCheck = styled(CheckIcon, {
  color: "$blue11",
})

let MainBody = styled("div", {
  backgroundColor: "$white",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "30px 20px",
  overflow: "auto",
  gap: "20px",
})

let HeaderRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
})

let HotelsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  flex: 1,
  overflow: "auto",
})

let MapContainer = styled("div", {
  display: "flex",
  width: "500px",
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
  border: "1px solid $gray6",
})
const MainFlex = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  flex: 1,
  width: "100%",
  height: "100%",
  overflow: "hidden",
})
const ButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
})

export default function ExploreHotelsPage() {
  let venuesQuery = useQuery({
    queryFn: () => getVenues(),
    queryKey: ["venues"],
  })
  let venues = venuesQuery.data?.venues ?? []
  return (
    <PageBody>
      <MainBody>
        <HeaderRow>
          <LinkOrText variant={"heading-lg"} text={"Venues"} />
        </HeaderRow>
        <ButtonsContainer>
          <Button
            text="Filters"
            variant="outline"
            endIcon={<Avatar label="3" color={"gray"} />}
          />
        </ButtonsContainer>
        <MainFlex>
          <HotelsContainer>
            {venues.map((venue) => {
              return <HotelCard hotelId={venue.id} />
            })}
          </HotelsContainer>
          <MapContainer>
            {/* <AppHotelLocationMap lat={40.73061} long={-73.935242} zoom={5} /> */}
          </MapContainer>
        </MainFlex>
      </MainBody>
    </PageBody>
  )
}

function HotelCard(props: {hotelId: string}) {
  let venueQuery = useQuery({
    queryFn: () => getVenue(props.hotelId),
    queryKey: ["venue", props.hotelId],
  })
  let [event] = useEvent()
  let venue = venueQuery.data?.venue
  let rfpStepsQuery = useQuery({
    queryFn: () => getRFPSteps(event.id, venue?.id ?? ""),
    queryKey: ["rfp-steps", event.id, "venue", venue?.id ?? ""],
  })
  let rfpStatus = rfpStepsQuery.isSuccess
    ? new RFPStatus(rfpStepsQuery.data!.rfp_steps)
    : null

  let isRequested = rfpStatus ? rfpStatus.hasRequestedProposal() : false
  return (
    <StyledLink
      to={AppRoutes.getPath("Venue", {
        venueId: props.hotelId,
      })}>
      <HotelCardC clickable>
        <HotelCardC.ActionButton
          startIcon={isRequested ? <BlueCheck /> : undefined}
          text={isRequested ? undefined : "Request Proposal"}
          color={isRequested ? "brand" : "gray"}
          variant={"outline"}
          disabled={isRequested}
        />
        <HotelCardC.Images
          images={[
            ...(venue
              ? venue.favorited_media
                  .sort((a, b) => {
                    return (b.is_favorite ? 1 : 0) - (a.is_favorite ? 1 : 0)
                  })
                  .map((venueMedia) => {
                    return {
                      src: venueMedia.media.media_url,
                      alt: venueMedia.media.alt,
                    }
                  })
              : []),
          ]}
        />
        <HotelCardC.Body>
          <HotelCardC.Section>
            <InfoLabelA
              variant={"heading-lg"}
              label={{
                text: venue?.attr_general_address?.formatted_address ?? "-",
              }}
              data={{text: venue?.name ?? "-"}}
            />
          </HotelCardC.Section>
          <HotelCardC.Section row>
            <HotelCardC.Label
              label={{text: "Airport Dist"}}
              data={{text: "-"}}
            />
            <HotelCardC.Label
              label={{text: "Guest Rooms"}}
              data={{
                text: venue?.attr_rooms_total
                  ? venue.attr_rooms_total.toString()
                  : "-",
              }}
            />
            <HotelCardC.Label
              label={{text: "Meeting Rooms"}}
              data={{
                text: venue?.attr_meeting_total_spaces
                  ? venue.attr_meeting_total_spaces.toString()
                  : "-",
              }}
            />
            <HotelCardC.Label label={{text: "Chain"}} data={{text: "-"}} />
          </HotelCardC.Section>
          <HotelCardC.Tags
            tags={["Type", "$$$", "Amenity", "Amenity", "Amenity"]}
          />
        </HotelCardC.Body>
      </HotelCardC>
    </StyledLink>
  )
}

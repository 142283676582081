import {ChevronLeftIcon, ChevronRightIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {styled} from "@summtech/flok-base/stitches.config"

const ButtonContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
})
export type BackAndForthButtonsProps = {
  onNext: () => void
  onBack?: () => void
  final?: boolean
  first?: boolean
}

export default function BackAndForthButtons(props: BackAndForthButtonsProps) {
  return (
    <ButtonContainer>
      <Button
        onClick={props.onBack}
        text="Back"
        variant={"outline"}
        color="gray"
        startIcon={<ChevronLeftIcon />}
        disabled={props.first}
      />
      <Button
        onClick={props.onNext}
        text={props.final ? "Submit" : "Next Step"}
        color="brand"
        variant="solid"
        endIcon={<ChevronRightIcon />}
      />
    </ButtonContainer>
  )
}

import {Button} from "@summtech/flok-base/components/Button"
import {FormField} from "@summtech/flok-base/components/FormField"
import {SelectItem} from "@summtech/flok-base/components/SelectItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {
  deleteEventProfileAgendaItem,
  getEventProfile,
  patchEventProfile,
  putEventProfileAgendaItem,
} from "@summtech/flok-core/api/event"
import AppModal, {AppModalProps} from "@summtech/flok-core/components/AppModal"
import {AccordionItem} from "@summtech/flok-core/components/layout/AccordionItem"
import {
  AgendaWizardType,
  EventProfileAgendaItemModel,
  EventProfileModel,
} from "@summtech/flok-core/models/event"
import {
  differenceInDays,
  formatTime,
  getIsoDate,
  timeToDate,
} from "@summtech/flok-core/utils"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useFormik} from "formik"
import _ from "lodash"
import {useState} from "react"
import {useHistory} from "react-router"
import {v4 as uuidv4} from "uuid"
import * as yup from "yup"
import {AppRoutes} from "../../Stack"
// Change import to flok-core
import BeforeUnload from "../BeforeUnload"
import {useEvent} from "../EventProvider"
import BackAndForthButtons from "./BackAndForthButtons"

const FormContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
})

const StyledContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
})

const AccordionBodyContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  width: "100%",
  paddingLeft: "18px",
})

const NoItemsContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "4px",
  width: "100%",
})

export default function AgendaForm(props: {
  submitRef: React.RefObject<HTMLButtonElement>
}) {
  let [event] = useEvent()
  let eventProfileId = event.event_profile_ids[0]
  const start_date = "2024-04-19"
  const samplePrefillAgenda = {
    0: [
      {
        name: "Welcome Reception",
        time_start: "10:00",
        time_end: "12:00",
        day: 0,
        number_of_attendees: 100,
        type: "meeting",
        uuid: uuidv4(),
      },
    ],
  }

  let history = useHistory()

  let [itemUuidsToDelete, setItemUuidsToDelete] = useState<string[]>([])
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  let queryClient = useQueryClient()
  let eventProfile = eventProfileQuery.data?.event_profile
  let days =
    eventProfile?.dates_arrival && eventProfile?.dates_departure
      ? differenceInDays(
          eventProfile.dates_departure,
          eventProfile.dates_arrival
        ) + 1
      : 0

  let agendaItems = eventProfile?.agenda_items ?? []

  let patchEventProfileMutation = useMutation({
    mutationFn: (variables: {eventProfile: Partial<EventProfileModel>}) =>
      patchEventProfile(eventProfileId, variables.eventProfile),
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
    onMutate: (variables) => {
      queryClient.setQueryData(["eventProfile", eventProfileId], {
        event_profile: {
          ...(eventProfileQuery.data?.event_profile ?? {}),
          ...variables.eventProfile,
        },
      })
    },
  })

  let putEventProfileAgendaItemMutation = useMutation({
    mutationFn: (variables: {agendaItem: EventProfileAgendaItemModel}) =>
      putEventProfileAgendaItem(variables.agendaItem),
    onError: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
    onMutate: (variables) => {
      let idx = agendaItems.findIndex(
        (item) => item.uuid === variables.agendaItem.uuid
      )
      let updatedAgendaItems = [...agendaItems]
      if (idx !== -1) {
        updatedAgendaItems[idx] = variables.agendaItem
      } else {
        updatedAgendaItems.push(variables.agendaItem)
      }
      queryClient.setQueryData(["eventProfile", eventProfileId], {
        event_profile: {
          ...(eventProfileQuery.data?.event_profile ?? {}),
          agenda_items: updatedAgendaItems,
        },
      })
    },
  })

  let deleteEventProfileAgendaItemMutation = useMutation({
    mutationFn: (variables: {agendaItemUuid: string}) =>
      deleteEventProfileAgendaItem(variables.agendaItemUuid),
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
    onMutate: (variables) => {
      let idx = agendaItems.findIndex(
        (item) => item.uuid === variables.agendaItemUuid
      )
      let updatedAgendaItems = [...agendaItems]
      if (idx !== -1) {
        updatedAgendaItems.splice(idx, 1)
      }
      queryClient.setQueryData(["eventProfile", eventProfileId], {
        event_profile: {
          ...(eventProfileQuery.data?.event_profile ?? {}),
          agenda_items: updatedAgendaItems,
        },
      })
    },
  })

  async function handleSubmit(
    values: typeof formik.values,
    completed: boolean
  ) {
    await patchEventProfileMutation.mutateAsync({
      eventProfile: {
        agenda_space_required: values.agenda_space_required === "true",
        agenda_wizard_required: values.agenda_wizard_required === "true",
        agenda_wizard_type: (values.agenda_wizard_type ||
          undefined) as AgendaWizardType,
        agenda_wizard_breakouts: values.agenda_wizard_breakouts === "true",
        agenda_completed: true,
      },
    })
    await Promise.all(
      Object.values(values.agenda_items)
        .flat()
        .map((item) => {
          if (item.local) {
            delete item.local
          }
          return putEventProfileAgendaItemMutation.mutateAsync({
            agendaItem: {
              ...item,
              type: item.type || undefined,
              event_profile_id: eventProfileId,
              day_type: "MAIN",
            },
          })
        })
    )
    return Promise.all(
      itemUuidsToDelete.map((uuid) => {
        return deleteEventProfileAgendaItemMutation.mutateAsync({
          agendaItemUuid: uuid,
        })
      })
    )
  }

  let formik = useFormik({
    initialValues: {
      agenda_space_required: (
        eventProfile?.agenda_space_required ?? ""
      ).toString(),
      agenda_wizard_required: (
        eventProfile?.agenda_wizard_required ?? ""
      ).toString(),
      agenda_wizard_type: eventProfile?.agenda_wizard_type ?? "",
      agenda_wizard_breakouts: (
        eventProfile?.agenda_wizard_breakouts ?? ""
      ).toString(),
      agenda_items: agendaItems.reduce(
        (
          prev: {
            [day: number]: (EventProfileAgendaItemModel & {local?: boolean})[]
          },
          curr
        ) => {
          return {...prev, [curr.day]: [...(prev[curr.day] ?? []), curr]}
        },
        {}
      ),
    },
    onSubmit: async (values) => {
      await handleSubmit(values, true)
      history.push(AppRoutes.getPath("EventProfileContactPage"))
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      agenda_wizard_required: yup.string().required("This field is required"),
    }),
  })
  let [creatingAgendaItemDay, setCreatingAgendaItemDay] = useState<
    undefined | number
  >(undefined)
  let [editingAgendaItemUuid, setEditingAgendaItemUuid] = useState<
    undefined | string
  >(undefined)

  return (
    <FormContainer>
      <BeforeUnload
        when={!_.isEqual(formik.values, formik.initialValues)}
        message="Are you sure you wish to leave without saving your changes?"
      />
      {(creatingAgendaItemDay !== undefined ||
        editingAgendaItemUuid !== undefined) && (
        <AgendaModal
          item={
            creatingAgendaItemDay !== undefined &&
            editingAgendaItemUuid !== undefined
              ? formik.values.agenda_items[creatingAgendaItemDay].find(
                  (item) => item.uuid === editingAgendaItemUuid
                )
              : undefined
          }
          open
          onClose={() => {
            setCreatingAgendaItemDay(undefined)
            setEditingAgendaItemUuid(undefined)
          }}
          onDelete={() => {
            if (
              creatingAgendaItemDay !== undefined &&
              editingAgendaItemUuid !== undefined
            ) {
              let index = formik.values.agenda_items[
                creatingAgendaItemDay
              ].findIndex((item) => item.uuid === editingAgendaItemUuid)

              if (
                !formik.values.agenda_items[creatingAgendaItemDay][index].local
              ) {
                setItemUuidsToDelete((itemsToDelete) => [
                  ...itemsToDelete,
                  editingAgendaItemUuid!,
                ])
              }
              formik.setFieldValue("agenda_items", {
                ...formik.values.agenda_items,
                [creatingAgendaItemDay]: [
                  ...formik.values.agenda_items[creatingAgendaItemDay].slice(
                    0,
                    index
                  ),
                  ...formik.values.agenda_items[creatingAgendaItemDay].slice(
                    index + 1
                  ),
                ],
              })
            }

            setCreatingAgendaItemDay(undefined)
            setEditingAgendaItemUuid(undefined)
          }}
          handleSubmit={(item) => {
            if (
              creatingAgendaItemDay !== undefined &&
              editingAgendaItemUuid === undefined
            ) {
              formik.setFieldValue("agenda_items", {
                ...formik.values.agenda_items,
                [creatingAgendaItemDay]: [
                  ...(formik.values.agenda_items[creatingAgendaItemDay]
                    ? formik.values.agenda_items[creatingAgendaItemDay]
                    : []),
                  {
                    ...item,
                    day: creatingAgendaItemDay,
                    uuid: uuidv4(),
                    local: true,
                  },
                ],
              })
            } else if (
              editingAgendaItemUuid !== undefined &&
              creatingAgendaItemDay !== undefined
            ) {
              let index = formik.values.agenda_items[
                creatingAgendaItemDay
              ].findIndex((item) => item.uuid === editingAgendaItemUuid)
              formik.setFieldValue("agenda_items", {
                ...formik.values.agenda_items,
                [creatingAgendaItemDay]: [
                  ...formik.values.agenda_items[creatingAgendaItemDay].slice(
                    0,
                    index
                  ),
                  item,
                  ...formik.values.agenda_items[creatingAgendaItemDay].slice(
                    index + 1
                  ),
                ],
              })
            }
            setCreatingAgendaItemDay(undefined)
            setEditingAgendaItemUuid(undefined)
          }}
        />
      )}
      <StyledContainer>
        <Text variant={"heading-lg"}>Let's talk about your event agenda</Text>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </Text>
      </StyledContainer>
      <FormField
        type="radio"
        value={formik.values.agenda_space_required}
        onValueChange={(newVal) => {
          formik.setFieldValue("agenda_space_required", newVal)
        }}
        label="Do you need meeting or event spaces at the hotel for group meals or team meetings?"
        options={[
          {label: "Yes", value: "true"},
          {label: "No", value: "false"},
        ]}
      />
      {formik.values.agenda_space_required === "true" && (
        <FormField
          value={formik.values.agenda_wizard_required}
          onValueChange={(newVal) => {
            formik.setFieldValue("agenda_wizard_required", newVal)
          }}
          type="radio"
          label="Do you have an expected agenda for your meeting and event space that you can share with the hotel?"
          options={[
            {label: "Yes", value: "false"},
            {label: "No", value: "true"},
          ]}
        />
      )}
      {formik.values.agenda_space_required === "true" &&
        formik.values.agenda_wizard_required === "true" && (
          <FormField
            value={formik.values.agenda_wizard_type}
            onValueChange={(newVal) => {
              formik.setFieldValue("agenda_wizard_type", newVal)
            }}
            type="radio"
            label="Which option makes most sense for your group?"
            options={[
              {label: "This event is very meetings heavy", value: "MEETING"},
              {
                label:
                  "This event has a healthy mix of team meetings and fun activities",
                value: "MIX",
              },
              {
                label:
                  "This event has mostly fun activities but we may need to meet once or twice",
                value: "ACTIVITY",
              },
            ]}
          />
        )}
      {formik.values.agenda_space_required === "true" &&
        formik.values.agenda_wizard_required === "true" &&
        formik.values.agenda_wizard_type !== "" && (
          <FormField
            value={formik.values.agenda_wizard_breakouts}
            onValueChange={(newVal) => {
              formik.setFieldValue("agenda_wizard_breakouts", newVal)
              if (
                Object.values(formik.values.agenda_items).flat().length === 0
              ) {
                // change here depending on values
                formik.setFieldValue("agenda_items", samplePrefillAgenda)
              }
            }}
            type="radio"
            label="Do you need breakout rooms for your meetings?"
            options={[
              {
                label:
                  "Yes, we need spaces for the full team as well as breakout spaces.",
                value: "true",
              },
              {
                label:
                  "No, we need spaces for the full team but no breakout spaces.",
                value: "false",
              },
            ]}
          />
        )}

      {((formik.values.agenda_space_required === "true" &&
        formik.values.agenda_wizard_required === "false") ||
        (formik.values.agenda_space_required === "true" &&
          formik.values.agenda_wizard_required === "true" &&
          formik.values.agenda_wizard_type !== "" &&
          formik.values.agenda_wizard_breakouts !== "")) && (
        <>
          {formik.values.agenda_space_required === "true" &&
            formik.values.agenda_wizard_required === "true" &&
            formik.values.agenda_wizard_type !== "" &&
            formik.values.agenda_wizard_breakouts !== "" && (
              <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
                We have generated a suggested agenda for your event. Please
                verify and change the agenda if needed.
              </Text>
            )}
          {new Array(days).fill(0).map((_, i) => {
            return (
              <AccordionItem
                defaultExpanded={i === 0}
                header={
                  <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
                    {new Intl.DateTimeFormat(undefined, {
                      day: "numeric",
                      month: "long",
                      timeZone: "UTC",
                    }).format(getIsoDate(start_date, i))}
                  </Text>
                }
                details={
                  <AccordionBodyContainer>
                    {formik.values.agenda_items[i] &&
                      formik.values.agenda_items[i]
                        .sort((a, b) => {
                          return timeToDate(a.time_start) >
                            timeToDate(b.time_start)
                            ? 1
                            : -1
                        })
                        .map((item, j) => {
                          return (
                            <AgendaItem
                              onEdit={() => {
                                setCreatingAgendaItemDay(i)
                                setEditingAgendaItemUuid(item.uuid)
                              }}
                              key={`agenda-item-${i}-${j}`}
                              title={item.title}
                              time={
                                item.time_start && item.time_end
                                  ? `${formatTime(
                                      item.time_start
                                    )} - ${formatTime(item.time_end)}`
                                  : ""
                              }
                              numberOfAttendees={item.number_of_attendees}
                            />
                          )
                        })}
                    {(!formik.values.agenda_items[i] ||
                      formik.values.agenda_items[i].length === 0) && (
                      <NoItemsContainer>
                        <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
                          No item added yet
                        </Text>
                        <Text variant={"text-sm"} css={{color: "$gray11"}}>
                          Add agenda items to plan your meeting space needs. If
                          you're not sure about your agenda yet, use one of our
                          agenda templates.
                        </Text>
                      </NoItemsContainer>
                    )}
                    <Button
                      onClick={() => {
                        setCreatingAgendaItemDay(i)
                      }}
                      variant={"outline"}
                      color="gray"
                      text="Add Item"
                    />
                  </AccordionBodyContainer>
                }
              />
            )
          })}
        </>
      )}

      <BackAndForthButtons
        onBack={() => {
          history.push(AppRoutes.getPath("EventProfileGuestRoomsPage"))
        }}
        onNext={() => {
          formik.handleSubmit()
        }}
      />
      <button
        style={{display: "none"}}
        ref={props.submitRef}
        onClick={async () => {
          await handleSubmit(formik.values, false)
          history.push(AppRoutes.getPath("EventProfilePage"))
        }}
      />
    </FormContainer>
  )
}

const AgendaItemContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
})

const AgendaTextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "2px",
  width: "200px",
})
function AgendaItem(props: {
  title: string
  time: string
  numberOfAttendees: number
  onEdit: () => void
}) {
  return (
    <AgendaItemContainer>
      <AgendaTextContainer>
        <Text variant={"text-sm-plus"}>{props.title}</Text>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          {props.time}
        </Text>
      </AgendaTextContainer>
      <Text variant={"text-sm"} css={{color: "$gray11"}}>
        {props.numberOfAttendees} Attendees
      </Text>
      <Button
        text="Edit"
        variant={"inline"}
        color="gray"
        onClick={() => {
          props.onEdit()
        }}
      />
    </AgendaItemContainer>
  )
}

const AgendaFormContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
})
const ButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "12px",
  alignItems: "center",
  marginLeft: "auto",
})

function AgendaModal(
  props: AppModalProps & {
    handleSubmit: (
      values: Omit<EventProfileAgendaItemModel, "uuid" | "day"> & {
        uuid?: string
        day?: number
      }
    ) => void
    item?: EventProfileAgendaItemModel
    onDelete: () => void
  }
) {
  let formik = useFormik({
    initialValues: {
      title: props.item?.title ?? "",
      time_start: props.item?.time_start ?? "",
      time_end: props.item?.time_end ?? "",
      number_of_attendees: props.item?.number_of_attendees ?? 0,
      type: props.item?.type ?? "",
    },
    onSubmit: (values) => {
      props.handleSubmit({
        ...(props.item ? props.item : {}),
        ...values,
      } as EventProfileAgendaItemModel)
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      title: yup.string().required("Item name is required"),
      time_start: yup.string().required("Start time is required"),
      time_end: yup.string().required("End time is required"),
    }),
  })

  let [confirmDelete, setConfirmDelete] = useState(false)
  return (
    <AppModal open={props.open} onClose={props.onClose}>
      <AppModal.Header>
        {props.item ? "Edit" : "Add"} Agenda Item
      </AppModal.Header>
      <AppModal.Body css={{width: "640px", flexShrink: 1}}>
        <AgendaFormContainer>
          <FormField
            inline
            fullWidth
            type="textfield"
            label="Name"
            errorMessage={formik.errors.title}
            value={formik.values.title}
            onChange={formik.handleChange}
            id="title"
          />
          <FormField
            inline
            type="time"
            label="Start Time"
            value={formik.values.time_start}
            errorMessage={formik.errors.time_start}
            onChange={(newVal) => {
              formik.setFieldValue("time_start", newVal)
            }}
          />
          <FormField
            inline
            type="time"
            errorMessage={formik.errors.time_end}
            minTime={formik.values.time_start}
            label="End Time"
            value={formik.values.time_end}
            onChange={(newVal) => {
              formik.setFieldValue("time_end", newVal)
            }}
          />
          <FormField
            inline
            type="number"
            fullWidth
            label="Attendees"
            onChange={formik.handleChange}
            value={formik.values.number_of_attendees}
            id="number_of_attendees"
          />
          <FormField
            inline
            type="select"
            fullWidth
            value={formik.values.type}
            label="Type"
            placeholder="Select type"
            onChange={(newVal) => {
              formik.setFieldValue("type", newVal)
            }}>
            <SelectItem value="MEETING" label="Meeting" />
            <SelectItem value="ACTIVITY" label="Activity" />
            <SelectItem value="MEAL" label="Meal" />
            <SelectItem value="BREAKOUT" label="Breakout" />
          </FormField>
        </AgendaFormContainer>
      </AppModal.Body>
      <AppModal.Footer css={{justifyContent: "space-between", float: "right"}}>
        {!props.item ? (
          <></>
        ) : confirmDelete ? (
          <Button
            variant={"solid"}
            color="critical"
            text="Are you sure you wish to delete?"
            onClick={props.onDelete}
          />
        ) : (
          <Button
            variant={"outline"}
            color="critical"
            text="Delete"
            onClick={() => {
              setConfirmDelete(true)
            }}
          />
        )}
        <ButtonsContainer>
          <Button
            variant={"outline"}
            color="gray"
            text="Cancel"
            onClick={props.onClose}
          />
          <Button
            type="submit"
            variant={"solid"}
            color="brand"
            text={props.item ? "Update Item" : "Add Item"}
            onClick={() => formik.handleSubmit()}
          />
        </ButtonsContainer>
      </AppModal.Footer>
    </AppModal>
  )
}

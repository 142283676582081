import {BlendingModeIcon} from "@radix-ui/react-icons"
import {Avatar} from "@summtech/flok-base/components/Avatar"
import {Button} from "@summtech/flok-base/components/Button"
import {FileDrawerTab} from "@summtech/flok-base/components/FileDrawerTab"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {getRFPSteps} from "@summtech/flok-core/api/event"
import {postRFPStep} from "@summtech/flok-core/api/rfp"
import {getVenue} from "@summtech/flok-core/api/venue"
import HotelCardA from "@summtech/flok-core/components/cards/HotelCardA"
import {HotelCardB} from "@summtech/flok-core/components/cards/HotelCardB"
import PageBody from "@summtech/flok-core/components/layout/PageBody"
import {RFPStatus} from "@summtech/flok-core/models/rfp"
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query"
import {useState} from "react"
import {Route, Switch, useHistory, useRouteMatch} from "react-router-dom"
import * as timeAgo from "timeago.js"
import {useEvent} from "../components/EventProvider"
import {HowItWorksModalButton} from "../components/modals/HowItWorksModal"
import ProposalModal from "../components/modals/ProposalModal"
import {AppRoutes} from "../Stack"
import {SortStepsByHotel} from "../utils"

let MainBody = styled("div", {
  backgroundColor: "$gray2",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  alignItems: "center",
  width: "100%",
  justifyContent: "center",
  paddingTop: "12px",
  flex: 1,
})

const TabInner = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
  padding: "0px 12px",
})

const MainBodyInner = styled("div", {
  display: "flex",
  flexDirection: "column",
  flex: 1,
  width: "100%",
  gap: "16px",
  borderTop: "1px solid $gray6",
  padding: "16px 32px",
  background: "$white",
})

export default function ProposalsPage() {
  let [event] = useEvent()
  let rfpStepsQuery = useQuery({
    queryFn: () => getRFPSteps(event.id),
    queryKey: ["rfp-steps", event.id],
    onSuccess: (data) => {},
  })

  let history = useHistory()

  let {path} = useRouteMatch()
  let tabValue =
    path === AppRoutes.getPath("ProposalsRequestedPage")
      ? "requested"
      : path === AppRoutes.getPath("ProposalsReceivedPage")
      ? "received"
      : path === AppRoutes.getPath("ProposalsShortlistPage")
      ? "shortlist"
      : path === AppRoutes.getPath("ProposalsContractPage")
      ? "contract"
      : ""

  let rfpStatuses = rfpStepsQuery.isSuccess
    ? SortStepsByHotel(rfpStepsQuery.data.rfp_steps).map((stepGroup) => {
        return new RFPStatus(stepGroup)
      })
    : []

  let [proposalModalVenueId, setProposalModalVenueId] = useState<
    string | undefined
  >(undefined)

  return (
    <PageBody>
      {proposalModalVenueId && (
        <ProposalModal
          venueId={proposalModalVenueId}
          onClose={() => {
            setProposalModalVenueId(undefined)
          }}
        />
      )}
      <MainBody>
        <TabsWrapper
          css={{overflow: "hidden"}}
          value={tabValue}
          onValueChange={(newTab) => {
            if (newTab === "requested") {
              history.push(AppRoutes.getPath(`ProposalsRequestedPage`))
            } else if (newTab === "received") {
              history.push(AppRoutes.getPath(`ProposalsReceivedPage`))
            } else if (newTab === "shortlist") {
              history.push(AppRoutes.getPath(`ProposalsShortlistPage`))
            } else if (newTab === "contract") {
              history.push(AppRoutes.getPath(`ProposalsContractPage`))
            }
          }}>
          <TabsList>
            <FileDrawerTab
              text={
                <TabInner>
                  <Text>Requested</Text>
                  <Avatar
                    color="gray"
                    label={rfpStatuses
                      .filter((status) => {
                        return status.isInBucket("REQUESTED")
                      })
                      .length.toString()}
                  />
                </TabInner>
              }
              value="requested"
            />
            <FileDrawerTab
              text={
                <TabInner>
                  <Text>Received</Text>
                  <Avatar
                    color="gray"
                    label={rfpStatuses
                      .filter((status) => {
                        return status.isInBucket("RECEIVED")
                      })
                      .length.toString()}
                  />
                </TabInner>
              }
              value="received"
            />
            <FileDrawerTab
              text={
                <TabInner>
                  <Text>Shortlist</Text>
                  <Avatar
                    color="gray"
                    label={rfpStatuses
                      .filter((status) => {
                        return status.isInBucket("SHORTLIST")
                      })
                      .length.toString()}
                  />
                </TabInner>
              }
              value="shortlist"
            />
            <FileDrawerTab
              text={
                <TabInner>
                  <Text>Contract</Text>
                  <Avatar
                    color="gray"
                    label={rfpStatuses
                      .filter((status) => {
                        return status.isInBucket("CONTRACT")
                      })
                      .length.toString()}
                  />
                </TabInner>
              }
              value="contract"
            />
          </TabsList>
        </TabsWrapper>
        <MainBodyInner>
          <Switch>
            <Route path={AppRoutes.getPath("ProposalsRequestedPage")}>
              <RequestedInner
                onClick={setProposalModalVenueId}
                rfpStatuses={rfpStatuses.filter((status) => {
                  return status.isInBucket("REQUESTED")
                })}
              />
            </Route>
            <Route path={AppRoutes.getPath("ProposalsReceivedPage")}>
              <ReceivedInner
                onClick={setProposalModalVenueId}
                rfpStatuses={rfpStatuses.filter((status) => {
                  return status.isInBucket("RECEIVED")
                })}
              />
            </Route>
            <Route path={AppRoutes.getPath("ProposalsShortlistPage")}>
              <ShortlistInner
                onClick={setProposalModalVenueId}
                rfpStatuses={rfpStatuses.filter((status) => {
                  return status.isInBucket("SHORTLIST")
                })}
              />
            </Route>
            <Route path={AppRoutes.getPath("ProposalsContractPage")}>
              <ContractInner
                onClick={setProposalModalVenueId}
                rfpStatuses={rfpStatuses.filter((status) => {
                  return status.isInBucket("CONTRACT")
                })}
              />
            </Route>
          </Switch>

          {/* </TabContent> */}
        </MainBodyInner>
      </MainBody>
    </PageBody>
  )
}

const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
})
const HeaderActionsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "8px",
})

function RequestedInner(props: {
  rfpStatuses: RFPStatus[]
  onClick: (venueId: string) => void
}) {
  let venueQueries = useQueries({
    queries: props.rfpStatuses.map((status) => {
      let venueId = status.getVenueId()
      return {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: () => getVenue(venueId),
        queryKey: ["venue", venueId],
      }
    }),
  })
  const noProposals =
    venueQueries.filter((query) => query.isSuccess).length === 0
  return (
    <>
      <HeaderContainer>
        <Text variant="heading-lg">Requested Proposals</Text>
        <HeaderActionsContainer>
          <HowItWorksModalButton />
        </HeaderActionsContainer>
      </HeaderContainer>
      {venueQueries
        .filter((query) => query.isSuccess)
        .map((venueQuery, index) => {
          let venue = venueQuery.data!.venue
          let status = props.rfpStatuses.find(
            (status) => status.getVenueId() === venue!.id
          )
          return (
            <HotelCardA
              key={venue?.id}
              onClick={() => {
                props.onClick(venue!.id)
              }}
              images={[
                {
                  src: "https://cdn.britannica.com/96/115096-050-5AFDAF5D/Bellagio-Hotel-Casino-Las-Vegas.jpg",
                  alt: "hotel britanica",
                },
              ]}
              name={venue?.name}
              location={"Cancun, Mexico"}
              status={
                status
                  ? `Requested ${timeAgo.format(
                      new Date(status!.getRequestedProposalDate() as string)
                    )}`
                  : "Unknown"
              }
            />
          )
        })}
      {noProposals && (
        <EmptySection
          title="No proposals requested "
          subtitle="Please go to the Venues page to search for venues and request proposals. Once requested, all proposals will be shown here."
          actionButton={<SearchVenuesButton />}
        />
      )}
    </>
  )
}

function ReceivedInner(props: {
  rfpStatuses: RFPStatus[]
  onClick: (venueId: string) => void
}) {
  let [event] = useEvent()
  let queryClient = useQueryClient()
  let venueQueries = useQueries({
    queries: props.rfpStatuses
      .map((status) => status.getVenueId())
      .map((venueId) => ({
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: () => getVenue(venueId),
        queryKey: ["venue", venueId],
      })),
  })
  let addToShortListMutation = useMutation({
    mutationFn: (variables: {venueId: string}) =>
      postRFPStep({
        event_id: event.id,
        venue_id: variables.venueId,
        type: "SHORTLIST_ADD",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["rfp-steps", event.id])
    },
  })

  const noProposals =
    venueQueries.filter((query) => query.isSuccess).length === 0
  return (
    <>
      <HeaderContainer>
        <Text variant="heading-lg">Received Proposals</Text>
        <HeaderActionsContainer>
          {!noProposals && <HowItWorksModalButton />}
        </HeaderActionsContainer>
      </HeaderContainer>
      {venueQueries
        .filter((query) => query.isSuccess)
        .map((venueQuery) => {
          let venue = venueQuery.data!.venue
          let status = props.rfpStatuses.find(
            (status) => status.getVenueId() === venue!.id
          )
          return (
            <HotelCardB
              key={venue?.id}
              onClick={() => {
                props.onClick(venue.id)
              }}
              actions={
                <Button
                  variant={"outline"}
                  text="Add To Shortlist"
                  onClick={(e) => {
                    e.stopPropagation()
                    addToShortListMutation.mutate({
                      venueId: venueQuery.data!.venue.id,
                    })
                  }}
                />
              }
              dates={["May 1, 2021 - May 3, 2021", "May 2, 2021"]}
              images={[
                {
                  src: "https://cdn.britannica.com/96/115096-050-5AFDAF5D/Bellagio-Hotel-Casino-Las-Vegas.jpg",
                  alt: "hotel britanica",
                },
              ]}
              name={venueQuery.data!.venue.name}
              location="Cancun, Mexico"
              status={
                status
                  ? `Received ${timeAgo.format(
                      new Date(status!.getSentProposalDate() as string)
                    )}`
                  : "Unknown"
              }
            />
          )
        })}
      {noProposals && (
        <EmptySection
          title="No proposals received"
          subtitle="Once a venue receives your request for a proposal, they will usually back to you within a couple of days."
          actionButton={<SearchVenuesButton />}
        />
      )}
    </>
  )
}

function ShortlistInner(props: {
  rfpStatuses: RFPStatus[]
  onClick: (venueId: string) => void
}) {
  let [event] = useEvent()
  let queryClient = useQueryClient()
  let venueQueries = useQueries({
    queries: props.rfpStatuses
      .map((status) => status.getVenueId())
      .map((venueId) => ({
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: () => getVenue(venueId),
        queryKey: ["venue", venueId],
      })),
  })
  let requestContractMutation = useMutation({
    mutationFn: (variables: {venueId: string}) =>
      postRFPStep({
        event_id: event.id,
        venue_id: variables.venueId,
        type: "CONTRACT_REQUEST",
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(["rfp-steps", event.id])
    },
  })
  const noProposals =
    venueQueries.filter((query) => query.isSuccess).length === 0
  // gather required concessions or they should be a part of the rfp step and status
  return (
    <>
      <HeaderContainer>
        <Text variant="heading-lg">Received Proposals</Text>
        <HeaderActionsContainer>
          {!noProposals && <HowItWorksModalButton />}
        </HeaderActionsContainer>
      </HeaderContainer>
      {venueQueries
        .filter((query) => query.isSuccess)
        .map((venueQuery) => {
          let status = props.rfpStatuses.find(
            (status) => status.getVenueId() === venueQuery.data!.venue.id
          )
          let venue = venueQuery.data!.venue
          return (
            <HotelCardB
              key={venue?.id}
              onClick={() => {
                props.onClick(venue.id)
              }}
              actions={
                <Button
                  disabled={!status?.submittedConcessions()}
                  variant={"outline"}
                  text="Request Contract"
                  onClick={(e) => {
                    e.stopPropagation()
                    requestContractMutation.mutate({
                      venueId: venueQuery.data!.venue.id,
                    })
                  }}
                />
              }
              roomRate="$200"
              dates={["May 1, 2021 - May 3, 2021", "May 2, 2021"]}
              images={[
                {
                  src: "https://cdn.britannica.com/96/115096-050-5AFDAF5D/Bellagio-Hotel-Casino-Las-Vegas.jpg",
                  alt: "hotel britanica",
                },
              ]}
              name={venueQuery.data!.venue.name}
              location="Cancun, Mexico"
              status={
                status
                  ? status!.submittedConcessions()
                    ? "Received final concessions"
                    : "Waiting for final concessions"
                  : "Unknown"
              }
            />
          )
        })}
      {noProposals && (
        <EmptySection
          actionButton={<ViewProposalsButton />}
          title="No venue shortlisted"
          subtitle="Once a venue receives your request for a proposal, they will usually back to you within a couple of days."
        />
      )}
    </>
  )
}

function ContractInner(props: {
  rfpStatuses: RFPStatus[]
  onClick: (venueId: string) => void
}) {
  let venueQueries = useQueries({
    queries: props.rfpStatuses
      .map((status) => status.getVenueId())
      .map((venueId) => ({
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: () => getVenue(venueId),
        queryKey: ["venue", venueId],
      })),
  })
  // need to add submit contract ability
  const noProposals =
    venueQueries.filter((query) => query.isSuccess).length === 0
  return (
    <>
      <HeaderContainer>
        <Text variant="heading-lg">Contract</Text>
        <HeaderActionsContainer>
          {!noProposals && <HowItWorksModalButton />}
        </HeaderActionsContainer>
      </HeaderContainer>
      {venueQueries
        .filter((query) => query.isSuccess)
        .map((venueQuery) => {
          let venue = venueQuery.data!.venue
          let status = props.rfpStatuses.find(
            (status) => status.getVenueId() === venueQuery.data!.venue.id
          )
          return (
            <HotelCardB
              key={venue?.id}
              onClick={() => {
                props.onClick(venue.id)
              }}
              actions={
                <Button
                  variant={"outline"}
                  text="Upload Contract"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                />
              }
              roomRate="$200"
              dates={["May 1, 2021 - May 3, 2021", "May 2, 2021"]}
              images={[
                {
                  src: "https://cdn.britannica.com/96/115096-050-5AFDAF5D/Bellagio-Hotel-Casino-Las-Vegas.jpg",
                  alt: "hotel britanica",
                },
              ]}
              name={venueQuery.data!.venue.name}
              location="Cancun, Mexico"
              status={
                status
                  ? status!.hasUploadedContract()
                    ? "Uploaded contract"
                    : "Requested contract"
                  : "Unknown"
              }
            />
          )
        })}
      {noProposals && (
        <EmptySection
          title="No contract requested"
          subtitle="Once a venue receives your request for a proposal, they will usually back to you within a couple of days."
          actionButton={<ViewProposalsButton />}
        />
      )}
    </>
  )
}

const EmptyIcon = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "28px",
  width: "28px",
  borderRadius: "8px",
  backgroundColor: "$gray3",
  marginBottom: "12px",
  "& *": {
    color: "$gray11",
  },
})

const EmptyContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "24px 14px",
  border: "1px solid $gray6",
  borderRadius: theme.shape.borderRadius,
  "& *": {
    textAlign: "center",
  },
})

const TextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  maxWidth: "400px",
})
const ButtonsContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "16px",
  gap: "8px",
})
function EmptySection(props: {
  title: string
  subtitle: string
  actionButton: JSX.Element
}) {
  return (
    <EmptyContainer>
      <EmptyIcon>
        <BlendingModeIcon />
      </EmptyIcon>
      <TextContainer>
        <Text variant="heading-base" css={{color: "$gray12"}}>
          {props.title}
        </Text>
        <Text variant="text-sm" css={{color: "$gray11"}}>
          {props.subtitle}
        </Text>
      </TextContainer>
      <ButtonsContainer>
        <HowItWorksModalButton text="Learn More" />
        {props.actionButton}
      </ButtonsContainer>
    </EmptyContainer>
  )
}

// Action Buttons
function SearchVenuesButton() {
  let history = useHistory()
  return (
    <Button
      color="brand"
      variant={"solid"}
      text="Search Venues"
      onClick={() => {
        history.push(AppRoutes.getPath("ExploreVenues"))
      }}
    />
  )
}

function ViewProposalsButton() {
  let history = useHistory()
  return (
    <Button
      color="brand"
      variant={"solid"}
      text="View Proposals"
      onClick={() => {
        history.push(AppRoutes.getPath("ProposalsReceivedPage"))
      }}
    />
  )
}

import {
  ArrowDownIcon,
  ArrowUpIcon,
  CheckIcon,
  TriangleDownIcon,
  TriangleUpIcon,
} from "@radix-ui/react-icons"
import {Dropdown} from "@summtech/flok-base/components/Dropdown"
import {DropdownItem} from "@summtech/flok-base/components/DropdownItem"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {
  CellContext,
  TableOptions,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table"
import {
  StyledCellCss,
  StyledSortIconCss,
  StyledTableHead,
  StyledTableHeaderCell,
} from "@summtech/flok-core/components/tables/TableComponents"
import {MeetingSpaceModel} from "@summtech/flok-core/models/venue"
import AppScrollSyncTable from "@summtech/flok-core/components/tables/AppScrollSyncTable"

const StyledIconImg = styled("img", {
  height: "32px",
  width: "32px",
})
const StyledTableDataCell = styled("td", StyledCellCss, {
  padding: "10px",
  background: theme.colors.white,
})
const StyledTableContainer = styled("div", {
  width: "100%",
  overflow: "hidden",
  heigth: "100%",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  maxHeight: "400px",
  border: "1px $gray6 solid",
  borderRadius: theme.shape.borderRadius,
})
const StyledTableHeaderIconCell = styled("th", StyledCellCss, {
  backgroundColor: theme.colors.white,
  padding: "10px",
  "& > span": {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})

const displayCell = (props: CellContext<MeetingSpaceModel, any>) =>
  props.getValue() ?? "-"

const HeaderToIcon = {
  capacity_max: (
    <StyledIconImg src="https://flok-b32d43c.s3.amazonaws.com/maximum.png" />
  ),
  capacity_u_shaped: (
    <StyledIconImg src="https://flok-b32d43c.s3.amazonaws.com/ushape.png" />
  ),
  capacity_banquet: (
    <StyledIconImg src="https://flok-b32d43c.s3.amazonaws.com/banquet.png" />
  ),
  capacity_cocktail: (
    <StyledIconImg src="https://flok-b32d43c.s3.amazonaws.com/cocktailrounds.png" />
  ),
  capacity_theater: (
    <StyledIconImg src="https://flok-b32d43c.s3.amazonaws.com/theater.png" />
  ),
  capacity_classroom: (
    <StyledIconImg src="https://flok-b32d43c.s3.amazonaws.com/classroom.png" />
  ),
  capacity_boardroom: undefined,
  capacity_hollow_square: undefined,
  capacity_crescent_rounds: undefined,
  room_size_sq_ft: undefined,
  ceiling_height_ft: undefined,
  room_dimensions: undefined,
}
export function MeetingSpacesTable(props: {
  meetingSpaces: MeetingSpaceModel[]
}) {
  let columnHelper = createColumnHelper<MeetingSpaceModel>()
  let options: TableOptions<MeetingSpaceModel> = {
    defaultColumn: {
      enableGlobalFilter: false,
      size: undefined,
    },
    columns: [
      columnHelper.accessor("name", {
        header: "Room Name",
        size: 250,
      }),
      columnHelper.accessor("capacity_max", {
        header: "Maximum Capacity",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_u_shaped", {
        header: "U-Shape",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_banquet", {
        header: "Banquet rounds",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_cocktail", {
        header: "Cocktail rounds",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_theater", {
        header: "Theater",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_classroom", {
        header: "Classroom",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_boardroom", {
        header: "Boardroom",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_hollow_square", {
        header: "Hollow Square",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("capacity_crescent_rounds", {
        header: "Crescent Rounds",
        size: 150,
        cell: displayCell,
      }),
      columnHelper.accessor("room_size_sq_ft", {
        header: "Room Size",
        size: 200,
        cell: (props: CellContext<MeetingSpaceModel, any>) =>
          props.getValue() ? `${props.getValue()} sq. ft` : "-",
      }),
      columnHelper.accessor("ceiling_height_ft", {
        header: "Ceiling Height",
        size: 200,
        cell: (props: CellContext<MeetingSpaceModel, any>) =>
          props.getValue() ? `${props.getValue()} ft` : "-",
      }),
      columnHelper.accessor("room_dimensions", {
        header: "Room Dimensions",
        size: 200,
        cell: displayCell,
      }),
    ],
    data: props.meetingSpaces,
    getSortedRowModel: getSortedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  }
  const table = useReactTable(options)

  return (
    <StyledTableContainer>
      <AppScrollSyncTable>
        <AppScrollSyncTable.Header className={StyledTableHead()}>
          <tr>
            {table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers
                .filter((header) => header.depth === 1)
                .map((header) => {
                  return (
                    <StyledTableHeaderIconCell
                      colSpan={header.colSpan}
                      gridLines
                      css={{
                        $$width: `${header.column.columnDef.size}px`,
                      }}>
                      <span>
                        {
                          HeaderToIcon[
                            header.column.id as keyof typeof HeaderToIcon
                          ]
                        }
                      </span>
                    </StyledTableHeaderIconCell>
                  )
                })
            )}
          </tr>
          <tr>
            {table.getHeaderGroups().map((headerGroup) =>
              headerGroup.headers
                .filter((header) => header.depth === 1)
                .map((header) => (
                  <Dropdown
                    portal
                    key={header.id}
                    button={
                      <StyledTableHeaderCell
                        colSpan={header.colSpan}
                        gridLines
                        css={{
                          $$width: `${header.column.columnDef.size}px`,
                          cursor: header.column.getCanSort()
                            ? "pointer"
                            : "inherit",
                        }}>
                        <span>
                          <Text variant="text-sm-plus">
                            {header.isPlaceholder
                              ? "\u00A0"
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </Text>
                          {header.column.getCanSort() &&
                          header.column.getIsSorted() === "asc" ? (
                            <TriangleUpIcon className={StyledSortIconCss()} />
                          ) : header.column.getIsSorted() === "desc" ? (
                            <TriangleDownIcon className={StyledSortIconCss()} />
                          ) : (
                            <></>
                          )}
                        </span>
                      </StyledTableHeaderCell>
                    }>
                    {header.column.getCanSort() && (
                      <DropdownItem
                        startIcon={<ArrowUpIcon />}
                        text={"Sort Ascending"}
                        endIcon={
                          header.column.getIsSorted() === "asc" ? (
                            <CheckIcon />
                          ) : undefined
                        }
                        onClick={
                          header.column.getIsSorted() === "asc"
                            ? () => header.column.clearSorting()
                            : () => header.column.toggleSorting(false)
                        }
                      />
                    )}
                    {header.column.getCanSort() && (
                      <DropdownItem
                        startIcon={<ArrowDownIcon />}
                        text={"Sort Descending"}
                        endIcon={
                          header.column.getIsSorted() === "desc" ? (
                            <CheckIcon />
                          ) : undefined
                        }
                        onClick={
                          header.column.getIsSorted() === "desc"
                            ? () => header.column.clearSorting()
                            : () => header.column.toggleSorting(true)
                        }
                      />
                    )}
                  </Dropdown>
                ))
            )}
          </tr>
        </AppScrollSyncTable.Header>
        <AppScrollSyncTable.Body>
          {table.getRowModel().rows.map((row, i) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <StyledTableDataCell
                  gridLines
                  css={{
                    $$width: `${cell.column.columnDef.size}px`,
                    ...(table.getRowModel().rows.length - 1 === i
                      ? {borderBottom: "none"}
                      : {}),
                  }}>
                  <Text variant="text-sm">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Text>
                </StyledTableDataCell>
              ))}
            </tr>
          ))}
        </AppScrollSyncTable.Body>
      </AppScrollSyncTable>
    </StyledTableContainer>
  )
}

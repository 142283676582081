import {Button} from "@summtech/flok-base/components/Button"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {getEventProfile, postEventProfile} from "@summtech/flok-core/api/event"
import CenterMessage from "@summtech/flok-core/components/layout/CenterMessage"
import {EventProfileDisplay} from "@summtech/flok-core/components/layout/EventProfileDisplay"
import PageBody from "@summtech/flok-core/components/layout/PageBody"
import {isEventProfileCompleted} from "@summtech/flok-core/utils"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useHistory} from "react-router"
import {useEvent} from "../components/EventProvider"
import {AppRoutes} from "../Stack"

let MainBody = styled("div", {
  backgroundColor: "$white",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  padding: "20px 32px",
  overflow: "auto",
  alignItems: "center",
  width: "100%",
})

let HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "space-between",
  marginBottom: "16px",
})

const WhitePage = styled("div", {
  backgroundColor: "$white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  flex: 1,
  textAlign: "center",
})

export default function EventProfilePage() {
  let [event] = useEvent()
  let history = useHistory()
  let eventProfileId = event.event_profile_ids[0]
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  let eventProfile = eventProfileQuery.data?.event_profile
  let queryClient = useQueryClient()
  let postEventProfileMutation = useMutation({
    mutationFn: () => postEventProfile(event.id),
    onSuccess: () => {
      queryClient.invalidateQueries(["event", event.id])
      history.push(AppRoutes.getPath("EventProfileGeneralPage"))
    },
  })
  return eventProfile ? (
    <PageBody>
      <MainBody>
        <HeaderContainer>
          <Text variant="heading-lg">Event Profile</Text>
          {!isEventProfileCompleted(eventProfile) && (
            <Button
              variant={"solid"}
              color="brand"
              text="Edit Event Profile"
              onClick={() => {
                history.push(AppRoutes.getPath("EventProfileGeneralPage"))
              }}
            />
          )}
        </HeaderContainer>
        <EventProfileDisplay eventProfile={eventProfile} />
      </MainBody>
    </PageBody>
  ) : (
    <WhitePage>
      <CenterMessage>
        <CenterMessage.Image src="https://flok-b32d43c.s3.amazonaws.com/EventProfileImg.png" />
        <CenterMessage.Body>
          <CenterMessage.Title>Create Event Profile</CenterMessage.Title>
          <CenterMessage.Description>
            Event profiles help venues to get a better idea what kind of event
            you want to organize.
          </CenterMessage.Description>
        </CenterMessage.Body>
        <CenterMessage.Actions>
          <Button variant={"outline"} text="Learn More" />
          <Button
            color="brand"
            text="Create Event Profile"
            onClick={() => {
              postEventProfileMutation.mutate()
            }}
          />
        </CenterMessage.Actions>
      </CenterMessage>
    </WhitePage>
  )
}

import {getEvent} from "@summtech/flok-core/api/event"
import {EventModel} from "@summtech/flok-core/models/event"
import {useQuery} from "@tanstack/react-query"
import {createContext, PropsWithChildren, useContext, useState} from "react"

const EventContext = createContext<
  {event: EventModel; setEventId: (id: number) => void} | undefined
>(undefined)

export function useEvent() {
  const eventContext = useContext(EventContext)
  if (eventContext === undefined) {
    throw Error("useEvent must be used within a EventProvider component")
  }
  return [eventContext.event, eventContext.setEventId] as const
}

export default function EventProvider(props: PropsWithChildren<{}>) {
  //   getUserHome()
  //   let user = useSelector((state: RootState) => state.user.user)
  //   if (!user) {
  //     throw Error("event provider needs to be in a ProtectedRoute")
  //   }

  let [eventId, setEventId] = useState(1)

  //   useEffect(() => {
  //     setEventId(user!.event_ids[0])
  //   }, [user])

  let eventQuery = useQuery({
    enabled: eventId != null,
    queryKey: ["events", eventId],
    queryFn: () => getEvent(eventId!),
  })

  //  add use effect for missing event id sends to events list page

  return eventQuery.status === "loading" ? (
    <>
      {/* <LoadingPage /> */}
      Loading
    </>
  ) : eventQuery.status === "error" ? (
    <>
      {/* <OopsPage /> */}
      Oops
    </>
  ) : (
    <EventContext.Provider
      value={{event: eventQuery.data.event, setEventId: setEventId}}>
      {props.children}
    </EventContext.Provider>
  )
}

import {ChevronRightIcon, DownloadIcon, FileIcon} from "@radix-ui/react-icons"
import {Button} from "@summtech/flok-base/components/Button"
import {Tab} from "@summtech/flok-base/components/Tab"
import {TabContent} from "@summtech/flok-base/components/TabContent"
import {TabsList} from "@summtech/flok-base/components/TabsList"
import {TabsWrapper} from "@summtech/flok-base/components/TabsWrapper"
import {Text} from "@summtech/flok-base/components/Text"
import {styled, theme} from "@summtech/flok-base/stitches.config"
import {getRFPSteps} from "@summtech/flok-core/api/event"
import {postRFPStep} from "@summtech/flok-core/api/rfp"
import {getVenue} from "@summtech/flok-core/api/venue"
import AccordionA from "@summtech/flok-core/components/accordions/AccordionA"
import AppModal from "@summtech/flok-core/components/AppModal"
import ConcessionCard from "@summtech/flok-core/components/cards/ConcessionCard"
import ProposalCard from "@summtech/flok-core/components/cards/ProposalCard"
import {Divider} from "@summtech/flok-core/components/common"
import {ImageGallery} from "@summtech/flok-core/components/image-displays/ImageGalleryModal"
import {InfoLabelA} from "@summtech/flok-core/components/InfoLabels"
import StepperB from "@summtech/flok-core/components/progress/StepperB"
import {RFPPostStepType, RFPStatus} from "@summtech/flok-core/models/rfp"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {ReactNode, useState} from "react"
import {
  AboutHotelSection,
  HotelFBSection,
  LocationHotelSection,
} from "../../pages/VenuePage"
import {useEvent} from "../EventProvider"
import {MeetingSpacesTable} from "../MeetingSpacesTable"

const ChevronRight = styled(ChevronRightIcon, {
  color: "$gray11",
})

const Header = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
})
const FlexRow = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "6px",
})
const DetailsBodyContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  width: "100%",
  overflow: "hidden",
})
const BodyLeft = styled("div", {
  display: "flex",
  flexDirection: "column",
  paddingRight: "20px",
  borderRight: "1px solid $gray6",
  height: "100%",
  flex: 1,
  padding: "16px 20px",
  maxHeight: "100%",
  overflow: "auto",
})
const BodyRight = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "288px",
  paddingRight: "20px",
  borderRight: "1px solid $gray6",
  height: "100%",
  padding: "16px 20px",
  flexShrink: 0,
  gap: "12px",
})
const StyledImage = styled("img", {
  width: "100%",
  objectFit: "cover",
  aspectRatio: "16/9",
  borderRadius: theme.shape.borderRadius,
})

const StepperContainer = styled("div", {
  display: "flex",
  width: "100%",
  padding: "16px 0px 12px 0px",
  border: "1px solid $gray6",
  borderRadius: theme.shape.borderRadius,
})

const MainLeftContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "100%",
  gap: "24px",
  marginTop: "20px",
})

const ButtonsContainer = styled("div", {
  display: "flex",
  width: "100%",
  gap: "12px",
  flexDirection: "column",
  marginTop: "4px",
})

const ActionButton = styled(Button, {
  color: "$gray12",
  justifyContent: "center",
})

const LocationSection = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "16px",
})

type ProposalModalTab = "details" | "venue" | "gallery"
export default function ProposalModal(props: {
  venueId: string
  onClose: () => void
}) {
  const [tabValue, setTabValue] = useState<ProposalModalTab>("details")
  let queryClient = useQueryClient()
  let venueQuery = useQuery({
    queryFn: () => getVenue(props.venueId),
    queryKey: ["venue", props.venueId],
  })
  let [event] = useEvent()

  let venue = venueQuery.data?.venue
  let rfpStepsQuery = useQuery({
    queryFn: () => getRFPSteps(event.id, props.venueId),
    queryKey: ["rfp-steps", event.id, "venue", props.venueId],
  })

  let postRFPStepMutation = useMutation({
    mutationFn: (variables: RFPPostStepType) =>
      postRFPStep({
        event_id: event.id,
        venue_id: props.venueId,
        ...variables,
      }),
    onSuccess: (data) => {
      queryClient.invalidateQueries(["rfp-steps", event.id])
      queryClient.invalidateQueries([
        "rfp-steps",
        event.id,
        "venue",
        props.venueId,
      ])
      if (data.rfp_step.type === "PROPOSAL_CANCEL") {
        props.onClose()
      }
    },
  })
  let rfpStatus = rfpStepsQuery.isSuccess
    ? new RFPStatus(rfpStepsQuery.data!.rfp_steps)
    : null

  // Helper Functions for render
  function returnActionButtons() {
    if (rfpStatus?.hasUploadedContract()) {
      return <></>
    } else if (rfpStatus?.hasRequestedContract()) {
      return (
        <>
          <ActionButton variant={"outline"} fullWidth text="Upload Contract" />
          <ActionButton
            variant={"outline"}
            fullWidth
            text="Cancel Contract Request"
            color="critical"
          />
          <ActionButton
            variant={"outline"}
            fullWidth
            text="Not Interested"
            color="critical"
          />
        </>
      )
    } else if (rfpStatus?.addedToShortlist()) {
      return (
        <>
          <ActionButton
            variant={"outline"}
            fullWidth
            text="Request Contract"
            disabled={!rfpStatus?.submittedConcessions()}
            onClick={() => {
              postRFPStepMutation.mutate({type: "CONTRACT_REQUEST"})
            }}
          />
          <ActionButton
            variant={"outline"}
            fullWidth
            text="Remove from Shortlist"
            onClick={() => {
              postRFPStepMutation.mutate({type: "SHORTLIST_REMOVE"})
            }}
            color="critical"
          />
          <ActionButton
            variant={"outline"}
            fullWidth
            text="Not Interested"
            color="critical"
          />
        </>
      )
    } else if (rfpStatus?.hasSentProposal()) {
      return (
        <>
          <ActionButton
            variant={"outline"}
            fullWidth
            text="Add to Shortlist"
            onClick={() => {
              postRFPStepMutation.mutate({type: "SHORTLIST_ADD"})
            }}
          />
          <ActionButton
            variant={"outline"}
            fullWidth
            text="Not Interested"
            color="critical"
          />
        </>
      )
    } else if (rfpStatus?.hasRequestedProposal() && !rfpStatus.isRejected()) {
      return (
        <ActionButton
          variant={"outline"}
          fullWidth
          onClick={() => {
            postRFPStepMutation.mutate({type: "PROPOSAL_CANCEL"})
          }}
          text="Cancel Request"
          color="critical"
        />
      )
    } else {
      return <></>
    }
  }

  function returnStatusText(): {title: string; subtitle: string} {
    if (rfpStatus?.hasUploadedContract()) {
      return {title: "Contract Uploaded", subtitle: "Lorem Ipsum"}
    } else if (rfpStatus?.hasRequestedContract()) {
      return {
        title: "You have requested a contract from this venue",
        subtitle:
          "We've sent an email to you and the venue instructing them to draft and send you a hotel contract. Finalize any revisions over email with the hotel. Once both parties have signed the contract, upload the final contract below to easily reference back on it later.",
      }
    } else if (rfpStatus?.submittedConcessions() && venue) {
      return {
        title: "You have received concessions from the venue!",
        subtitle: `The ${
          venue!.name
        } has sent you their final concessions, please review them below. To move forward with this venue, request a contract. Be aware that you can only request a contract from one venue at a time.`,
      }
    } else if (rfpStatus?.addedToShortlist()) {
      return {
        title: "Waiting for the venue to send final concessions...",
        subtitle:
          "We've notified the venue that you have added them to your shortlist. They'll get back to you within a couple of days with their best and final concessions.",
      }
    } else if (rfpStatus?.hasSentProposal() && venue) {
      return {
        title: "You have received a proposal from the venue!",
        subtitle: `The ${venue.name} has sent you a proposal, please review it below. To move forward with this venue, add them to your shortlist. The venue will get back to your with their best and final concessions. Be aware that you can only add up to three venues to your shortlist at a time.`,
      }
    } else if (
      !rfpStatus?.isRejected() &&
      rfpStatus?.hasRequestedProposal() &&
      venue
    ) {
      return {
        title: `Waiting for the venue to respond to your proposal request...`,
        subtitle: `We've requested proposal from the ${venue.name}. Venues usually get back with a proposal within a week. You'll receive an email notification as soon as they respond to your request.`,
      }
    } else {
      return {
        title: "",
        subtitle: "",
      }
    }
  }

  // End Helper Functions for render
  return (
    <TabsWrapper
      onValueChange={(tabValue) => setTabValue(tabValue as ProposalModalTab)}
      value={tabValue}>
      <AppModal
        open
        onClose={props.onClose}
        css={{maxWidth: "1050px", height: "100%", width: "100%"}}>
        {venue && rfpStatus ? (
          <>
            <AppModal.Header css={{paddingBottom: "0px"}}>
              <Header>
                <FlexRow>
                  <Text variant={"heading-lg"}>Proposals</Text>
                  <ChevronRight />
                  <Text variant={"heading-lg"}>{venue.name}</Text>
                </FlexRow>
                <TabsList>
                  <Tab value="details" text="Details"></Tab>
                  <Tab value="venue" text="Venue"></Tab>
                  <Tab value="gallery" text="Gallery"></Tab>
                </TabsList>
              </Header>
            </AppModal.Header>
            <AppModal.Body css={{padding: "0px"}}>
              <TabContent
                value="details"
                css={{
                  flex: 1,
                  display: "flex",
                  overflow: "hidden",
                }}>
                <DetailsBodyContainer>
                  <BodyLeft>
                    <Text variant="text-sm-plus" css={{color: "$gray11"}}>
                      Status
                    </Text>
                    <Text variant="heading-base" css={{color: "$gray12"}}>
                      {returnStatusText().title}
                    </Text>
                    <Text
                      variant="text-sm-plus"
                      css={{color: "$gray11", marginTop: "8px"}}>
                      {returnStatusText().subtitle}
                    </Text>
                    <MainLeftContainer>
                      <StepperContainer>
                        <StepperB
                          steps={[
                            {
                              label: "Proposal Requested",
                              completed: rfpStatus.hasRequestedProposal(),
                            },
                            {
                              label: "Proposal Received",
                              completed: rfpStatus.hasSentProposal(),
                            },
                            {
                              label: "Added to Shortlist",
                              completed: rfpStatus.addedToShortlist(),
                            },
                            {
                              label: "Concessions Received",
                              completed: rfpStatus.submittedConcessions(),
                            },
                            {
                              label: "Contract Requested",
                              completed: rfpStatus.hasRequestedContract(),
                            },
                            {
                              label: "Contract Uploaded",
                              completed: rfpStatus.hasUploadedContract(),
                            },
                          ]}
                        />
                      </StepperContainer>
                      <AccordionA heading="Proposal" defaultExpanded>
                        <AccordionA.Body>
                          {rfpStatus.getProposalId("event") ? (
                            <ProposalCard
                              proposalId={rfpStatus.getProposalId("event")!}
                            />
                          ) : (
                            <AccordionPlaceholder
                              title="Proposal will be shown here"
                              icon
                              subtitle="You haven't received a proposal from this venue yet. It will be shown here once the venue has send it."
                            />
                          )}
                        </AccordionA.Body>
                      </AccordionA>
                      <AccordionA heading="Concessions" defaultExpanded>
                        <AccordionA.Body>
                          {rfpStatus.getConcessionId("event") ? (
                            <ConcessionCard
                              concessionId={rfpStatus.getConcessionId("event")!}
                            />
                          ) : (
                            <AccordionPlaceholder
                              title="Concessions will be shown here"
                              icon
                              subtitle="You haven't received concessions from this venue yet. They will be shown here once the venue has send them."
                            />
                          )}
                        </AccordionA.Body>
                      </AccordionA>
                      <AccordionA heading="Contract" defaultExpanded>
                        <AccordionA.Body>
                          <AccordionPlaceholder
                            title="Contract will be shown here"
                            icon
                            subtitle="You haven't received a contract from this venue yet. It will be shown here once the venue has send them."
                          />
                        </AccordionA.Body>
                      </AccordionA>
                    </MainLeftContainer>
                  </BodyLeft>
                  <BodyRight>
                    <StyledImage
                      alt={"hotel image"}
                      src={
                        "https://images.pexels.com/photos/258154/pexels-photo-258154.jpeg?cs=srgb&dl=pexels-pixabay-258154.jpg&fm=jpg"
                      }
                    />
                    <InfoLabelA
                      variant={"heading-base"}
                      data={{text: venue.name}}
                      label={{
                        text:
                          venue.attr_general_address?.formatted_address ?? "",
                      }}
                    />
                    <InfoLabelA
                      data={{text: "May 1 - May 5, 2022"}}
                      label={{text: "Preferred Date"}}
                    />
                    <InfoLabelA
                      data={{text: "$145"}}
                      label={{text: "Avg. Room Rate per Person"}}
                    />
                    <ButtonsContainer>{returnActionButtons()}</ButtonsContainer>
                  </BodyRight>
                </DetailsBodyContainer>
              </TabContent>
              <TabContent
                value="venue"
                css={{
                  padding: "16px 24px",
                  gap: "32px",
                  display: "flex",
                  flexDirection: "column",
                }}>
                {venue && (
                  <AboutHotelSection variant="heading-base" venue={venue} />
                )}
                <Divider />
                <LocationSection>
                  <Text variant="heading-base" css={{color: "$gray12"}}>
                    Location
                  </Text>
                  <LocationHotelSection variant="horizontal" venue={venue} />
                </LocationSection>
                <Divider />
                <Text variant="heading-base" css={{color: "$gray12"}}>
                  Meeting Spaces
                </Text>
                <MeetingSpacesTable
                  meetingSpaces={venue?.meeting_spaces ?? []}
                />
                <Button
                  text="Download AV Menu"
                  variant={"outline"}
                  color="gray"
                  startIcon={<DownloadIcon />}
                />
                <Divider />
                {venue && (
                  <HotelFBSection variant={"heading-base"} venue={venue} />
                )}
              </TabContent>
              <TabContent value="gallery">
                <ImageGallery
                  imageGroups={
                    venue
                      ? venue.attr_general_media_groups.map((group) => {
                          return {
                            tag: group.name,
                            images: group.group_membership.map((membership) => {
                              return {
                                src: membership.venue_media.media.media_url,
                                alt: membership.venue_media.media.alt,
                              }
                            }),
                          }
                        })
                      : []
                  }
                />
              </TabContent>
            </AppModal.Body>
          </>
        ) : (
          //   <AppLoadingScreen />
          <>Add loading screen</>
        )}
      </AppModal>
    </TabsWrapper>
  )
}

const PlaceholderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  gap: "12px",
  padding: "24px 20px",
})
const TextContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  alignItems: "center",
  gap: "2px",
  textAlign: "center",
})
const IconBox = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: theme.shape.borderRadius,
  background: "$gray3",
  height: "28px",
  width: "28px",
})

const GrayFile = styled(FileIcon, {
  color: "$gray11",
})
function AccordionPlaceholder(props: {
  icon?: boolean
  actions?: ReactNode
  title: string
  subtitle: string
}) {
  return (
    <PlaceholderContainer>
      {props.icon && (
        <IconBox>
          <GrayFile />
        </IconBox>
      )}
      <TextContainer>
        <Text variant={"text-sm-plus"} css={{color: "$gray12"}}>
          {props.title}
        </Text>
        <Text variant={"text-xs"} css={{color: "$gray11"}}>
          {props.subtitle}
        </Text>
      </TextContainer>
      {props.actions}
    </PlaceholderContainer>
  )
}

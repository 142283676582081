import {RFPStepModel} from "@summtech/flok-core/models/rfp"

export function SortStepsByHotel(steps: RFPStepModel[]) {
  let stepsMap: {[venueId: string]: RFPStepModel[]} = {}
  steps.forEach((step) => {
    if (stepsMap[step.venue_id]) {
      stepsMap[step.venue_id].push(step)
    } else {
      stepsMap[step.venue_id] = [step]
    }
  })
  return Object.values(stepsMap)
}

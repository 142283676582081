import {FormField} from "@summtech/flok-base/components/FormField"
import {Text} from "@summtech/flok-base/components/Text"
import {styled} from "@summtech/flok-base/stitches.config"
import {getEventProfile, patchEventProfile} from "@summtech/flok-core/api/event"
import {EventProfileModel} from "@summtech/flok-core/models/event"
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query"
import {useFormik} from "formik"
import _ from "lodash"
import {useHistory} from "react-router"
import * as yup from "yup"
import {AppRoutes} from "../../Stack"
// Change import to flok-core
import BeforeUnload from "../BeforeUnload"
import {useEvent} from "../EventProvider"
import BackAndForthButtons from "./BackAndForthButtons"

const FormContainer = styled("div", {
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
})

const DatesContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "16px",
})
const DateContainer = styled("div", {
  width: "50%",
  flex: 1,
  display: "flex",
})
const StyledContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  gap: "12px",
  width: "100%",
})
export default function DatesForm(props: {
  submitRef: React.RefObject<HTMLButtonElement>
}) {
  let [event] = useEvent()
  let history = useHistory()
  let eventProfileId = event.event_profile_ids[0]
  let eventProfileQuery = useQuery({
    queryKey: ["eventProfile", eventProfileId],
    queryFn: () => getEventProfile(eventProfileId),
  })
  let queryClient = useQueryClient()
  let eventProfile = eventProfileQuery.data?.event_profile
  let patchEventProfileMutation = useMutation({
    mutationFn: (variables: {eventProfile: Partial<EventProfileModel>}) =>
      patchEventProfile(eventProfileId, variables.eventProfile),
    onMutate: (variables) => {
      queryClient.setQueryData(["eventProfile", eventProfileId], {
        event_profile: {
          ...eventProfileQuery.data?.event_profile,
          ...variables.eventProfile,
        },
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries(["eventProfile", eventProfileId])
    },
  })

  async function handleSubmit(
    values: typeof formik.values,
    completed: boolean
  ) {
    let response = await formik.validateForm()
    return patchEventProfileMutation.mutateAsync({
      eventProfile: {
        ...values,
        dates_arrival: values.dates_arrival || null,
        dates_departure: values.dates_departure || null,
        dates_completed: completed || Object.values(response).length === 0,
      } as unknown as Partial<EventProfileModel>,
    })
  }
  let formik = useFormik({
    initialValues: {
      dates_arrival: eventProfile?.dates_arrival ?? "",
      dates_departure: eventProfile?.dates_departure ?? "",
      dates_flexible:
        eventProfile?.dates_flexible !== undefined
          ? eventProfile.dates_flexible
            ? "true"
            : "false"
          : "",
      dates_alternative: eventProfile?.dates_alternative ?? "",
    },
    onSubmit: async (values) => {
      await handleSubmit(values, true)
      history.push(AppRoutes.getPath("EventProfileGuestRoomsPage"))
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      dates_flexible: yup.string().required("This field is required"),
      dates_arrival: yup.string().required("Arrival date is required"),
      dates_departure: yup.string().required("Departure date is requried"),
    }),
  })
  return (
    <FormContainer>
      <BeforeUnload
        when={!_.isEqual(formik.values, formik.initialValues)}
        message="Are you sure you wish to leave without saving your changes?"
      />
      <StyledContainer>
        <Text variant={"heading-lg"}>When you are planning to go?</Text>
        <Text variant={"text-sm"} css={{color: "$gray11"}}>
          Specify the preferred dates for your event.
        </Text>
      </StyledContainer>
      <DatesContainer>
        <DateContainer>
          <FormField
            type="datetime"
            label="Arriving"
            fullWidth
            errorMessage={formik.errors.dates_arrival}
            value={formik.values.dates_arrival}
            onChange={(newValue) => {
              formik.setFieldValue("dates_arrival", newValue)
            }}
          />
        </DateContainer>
        <DateContainer>
          <FormField
            minDate={formik.values.dates_arrival}
            type="datetime"
            label="Departing"
            errorMessage={formik.errors.dates_departure}
            fullWidth
            value={formik.values.dates_departure}
            onChange={(newValue) => {
              formik.setFieldValue("dates_departure", newValue)
            }}
          />
        </DateContainer>
      </DatesContainer>
      <FormField
        value={formik.values.dates_flexible}
        errorMessage={formik.errors.dates_flexible}
        type="radio"
        label="Are your dates flexible?"
        onValueChange={(newValue) => {
          formik.setFieldValue("dates_flexible", newValue)
        }}
        options={[
          {label: "Yes", value: "true"},
          {label: "No", value: "false"},
        ]}
      />
      <FormField
        value={formik.values.dates_alternative}
        onChange={formik.handleChange}
        id="dates_alternative"
        type="textarea"
        label="What alternative dates would work for you?"
        fullWidth
      />
      <BackAndForthButtons
        onBack={() => {
          history.push(AppRoutes.getPath("EventProfileGeneralPage"))
        }}
        onNext={() => {
          formik.handleSubmit()
        }}
      />
      <button
        style={{display: "none"}}
        ref={props.submitRef}
        onClick={async () => {
          await handleSubmit(formik.values, false)
          history.push(AppRoutes.getPath("EventProfilePage"))
        }}
      />
    </FormContainer>
  )
}
